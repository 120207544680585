import { MouseEventHandler, useCallback, useMemo } from 'react';
import { OnTimeOfDaySelect, OnTimeValueSelect, TimeSelectorTimeOfDay, TimeSelectorProps } from './interfaces';
import { getHourItems, getMinuteItems, getHourFormatItems } from './lib';

interface Options extends TimeSelectorProps {}

interface Returned {
    items: string[];
    onOptionClick: (value: string) => MouseEventHandler;
}

export function useTimeSelector(options: Options): Returned {
    const { mode, hoursFormat, hourStep = 1, minuteStep = 5, secondStep = 5, onSelect } = options;

    const items = useMemo(() => {
        if (mode === 'hour' && hoursFormat !== undefined && hourStep !== undefined) {
            return getHourItems({
                hoursFormat,
                hourStep,
            });
        }
        if (mode === 'minute' && minuteStep !== undefined)
            return getMinuteItems({
                step: minuteStep,
            });
        if (mode === 'second' && secondStep !== undefined)
            return getMinuteItems({
                step: secondStep,
            });
        return getHourFormatItems();
    }, [mode, hoursFormat, hourStep, minuteStep, secondStep]);

    const onOptionClick = useCallback<(value: string) => MouseEventHandler>(
        (value) => () => {
            if (mode === 'time-of-day') (onSelect as OnTimeOfDaySelect)(value as TimeSelectorTimeOfDay);
            else (onSelect as OnTimeValueSelect)(+value);
        },
        [onSelect, mode],
    );

    return {
        items,
        onOptionClick,
    };
}
