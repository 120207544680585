import React from 'react';
import { useModalGeneralForPublic, Returned } from '../useModalGeneralForPublic';
import { ModalProps, ModalCallingProps } from '@features/Modal';

export const useModalPublic = <P extends ModalProps = any>(
    Component: React.FC<P>,
    queryName?: string,
): Returned<Omit<P, keyof ModalProps> & ModalCallingProps> => {
    const { open } = useModalGeneralForPublic(Component, 'modal', queryName);
    return { open };
};
