import { Api } from '../../Api';
import { IUserDTO, IUsersList, IUserCreateDto } from './interfaces';
import { QuantumUsersPutBody } from './body';
import { QuantumUsersGetByIdParams, QuantumUsersPutByIdParams, QuantumUsersDeleteByIdParams } from './params';
import { QuantumUsersGetQuery } from './query';

export class Users {
    private static _basePath = '/Users';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${Users._basePath}`;
        return {
            async get(query: QuantumUsersGetQuery) {
                const path = '/EndUsers/GetAll';
                return await Api.get<IUsersList>({
                    path: `${basePath}${path}`,
                    query,
                });
            },
            async post(body: IUserCreateDto) {
                const path = '/Create';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async getById({ id }: QuantumUsersGetByIdParams) {
                const path = '/EndUsers/';
                return await Api.get<IUserCreateDto>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async putById({ id }: QuantumUsersPutByIdParams, body: IUserCreateDto) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async deleteById({ id }: QuantumUsersDeleteByIdParams) {
                const path = '/Delete/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
