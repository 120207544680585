import { Api } from '../../Api';
import { ListResult } from '../../interfaces';
import { QuantumTradeStatusesTemplateDto, QuantumTradeStatusesTemplateFullDto } from './interfaces';
import { QuantumTradeStatusesPostBody, QuantumTradeStatusesPutBody } from './body';
import {
    QuantumTradeStatusesGetByIdParams,
    QuantumTradeStatusesPutByIdParams,
    QuantumTradeStatusesDeleteByIdParams,
} from './params';
import { QuantumTradeStatusesGetQuery } from './query';
import { getQuantumTradeStatusesMockTemplates, getQuantumTradeStatusesMockTemplate } from './mocks';

export class TradeStatus {
    private static _basePath = '/trade-status';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${TradeStatus._basePath}`;
        return {
            async get(query: QuantumTradeStatusesGetQuery, isMock: boolean = false) {
                const path = '/';
                return await Api.get<ListResult<QuantumTradeStatusesTemplateDto>>({
                    path: `${basePath}${path}`,
                    query,
                    isMock,
                    mock: () =>
                        getQuantumTradeStatusesMockTemplates({ page: query.page, pageSize: query.recordsCount }),
                    mockName: 'Get Trade Templates',
                });
            },
            async post(body: QuantumTradeStatusesPostBody, isMock: boolean = false) {
                const path = '/';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Post Trade Template',
                });
            },
            async getById({ id }: QuantumTradeStatusesGetByIdParams, isMock: boolean = false) {
                const path = '/';
                return await Api.get<QuantumTradeStatusesTemplateFullDto>({
                    path: `${basePath}${path}${id}`,
                    isMock,
                    mock: getQuantumTradeStatusesMockTemplate,
                    mockName: 'Get Trade Template By Id',
                });
            },
            async putById(
                { id }: QuantumTradeStatusesPutByIdParams,
                body: QuantumTradeStatusesPutBody,
                isMock: boolean = false,
            ) {
                const path = '/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Put Trade Template By Id',
                });
            },
            async deleteById({ id }: QuantumTradeStatusesDeleteByIdParams, isMock: boolean = false) {
                const path = '/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Delete Trade Template By Id',
                });
            },
        };
    }
}
