import { compose } from '@shared/utils';
import { withWrapper } from '@shared/react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@processes/ErrorBoundary';
import { OpenIdProvider } from '@entities/OpenIDProvider';
import { MainLayout } from '@widgets/MainLayout';
import { NotifyProvider } from '@features/Notify';
import { ModalProvider } from '@features/Modal';

export const withProviders = compose(
    // FIXME: define types correctly
    // eslint-disable-next-line
    // @ts-ignore
    withWrapper(ErrorBoundary),
    withWrapper(BrowserRouter),
    withWrapper(OpenIdProvider), //todo qts auth
    withWrapper(NotifyProvider),
    withWrapper(ModalProvider),
    withWrapper(MainLayout),
);
