import React, { useCallback } from 'react';
import { useModal } from '../ModalProvider';
import { ModalProps, ModalType } from '../../interfaces';

export interface Returned<P extends Record<string, any> = any> {
    open: (props: P) => void;
}

export const useModalGeneralForPublic = <P extends ModalProps = any>(
    Component: React.FC<P>,
    type: ModalType,
    queryName?: string,
): Returned<Omit<P, keyof ModalProps>> => {
    const { pushModal } = useModal();

    const open = useCallback(
        (props: Omit<P, keyof ModalProps>) => {
            pushModal({
                type,
                Component: Component as React.FC<ModalProps>,
                props,
                queryName,
            });
        },
        [pushModal, Component, queryName],
    );

    return { open };
};
