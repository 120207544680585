import React from 'react';
import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { OpenIdContext, IOpenIdContext } from './OpenIdContext';
import { withOIDCProvider } from './withOIDCProvider';
import { useClaims } from './lib';
import { AppLoader } from '@shared/UI';
import { QuantumUser } from '@shared/api';

const OpenIdProviderContent: React.FC<React.PropsWithChildren> = (props) => {
    const { children } = props;

    const { logout } = useOidc();
    const { oidcUser } = useOidcUser();

    const { permissions, features, token } = useClaims();

    const value: IOpenIdContext = {
        logout,
        user: oidcUser as QuantumUser,
        permissions,
        features,
        token,
    };

    // FIXME: remove App Loader !!!
    if (!token) return <AppLoader />;

    return <OpenIdContext.Provider value={value}>{children}</OpenIdContext.Provider>;
};

export const OpenIdProvider = withOIDCProvider(OpenIdProviderContent);
