import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { InputProps } from './interfaces';
import { InfoLabel } from '../InfoLabel';
import classNames from 'classnames';
import './Input.scss';
import { Tag } from '../Tag';
import { RiErrorWarningLine } from 'react-icons/ri';
import { InfoIcon } from '../InfoIcon';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';

export const Input: React.FC<InputProps> = (props) => {
    const {
        inputSize = 'medium',
        prefixIcon,
        suffixIcon,
        onPrefixIconClick,
        onSuffixIconClick,
        wrapperClassName,
        className,
        onFocus,
        onBlur,
        disabled,
        onWrapperClick: externalOnWrapperClick,
        label,
        infoTooltip,
        feedback,
        placeholder,
        value,
        type = 'text',
        textarea = false,
        selectedTags,
        removeTag,
        onChange,
        movableLabel,
        name,
        ...rest
    } = props;

    const input = useRef<HTMLInputElement>(null);

    const [focus, setFocus] = useState<boolean>(false);

    const [openEye, setOpenEye] = useState(false);

    const onWrapperClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
        (event) => {
            // input?.current?.focus();
            externalOnWrapperClick?.(event);
        },
        [input, externalOnWrapperClick],
    );

    const onInputFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        (event) => {
            onFocus?.(event);
            setFocus(true);
        },
        [onFocus],
    );

    const onInputBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        (event) => {
            onBlur?.(event);
            setFocus(false);
        },
        [onBlur],
    );

    const labelClick = useCallback<MouseEventHandler>(
        (event) => {
            if (focus) event?.stopPropagation();
        },
        [focus],
    );

    return (
        <div className='ui-input-wrapper'>
            <div
                className={classNames(
                    'ui-input',
                    className,
                    wrapperClassName,
                    { focus },
                    { disabled },
                    { focused: movableLabel && (focus || value || selectedTags?.length) },
                    { invalid: !!feedback },
                )}
                onClick={onWrapperClick}
            >
                {label && (
                    <InfoLabel
                        label={label}
                        tooltip={infoTooltip}
                        className={classNames(
                            'ui-input__label',
                            inputSize,
                            {
                                active: focus || value,
                            },
                            {
                                iconLeft: prefixIcon,
                            },
                            { textarea },
                        )}
                        onClick={labelClick}
                    />
                )}
                {prefixIcon && (
                    <aside
                        className={classNames('ui-input__icon prefix', { focus }, inputSize, {
                            interactive: !!onPrefixIconClick,
                        })}
                        onClick={onPrefixIconClick}
                    >
                        {prefixIcon}
                    </aside>
                )}
                {!!selectedTags?.length && (
                    <div className='ui-input__tags'>
                        {selectedTags.map((item) => (
                            <Tag
                                key={item.value}
                                onRemove={() => removeTag(item)}
                            >
                                {item.label}
                            </Tag>
                        ))}
                    </div>
                )}
                {!textarea && (
                    <input
                        {...rest}
                        ref={input}
                        autoComplete='off'
                        className={classNames(
                            'ui-input__input',
                            { 'ui-input-multiple': selectedTags?.length },
                            inputSize,
                            { focus },
                            className,
                        )}
                        onChange={onChange}
                        name={name}
                        type={type === 'password' && openEye ? 'text' : type}
                        value={value}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                )}
                {movableLabel && (
                    <div
                        className={classNames('movable-label', {
                            movableLabel: type === 'text',
                            selectMovableLabel: type === 'select',
                            labelAbove: value,
                            selectLabelAbove: selectedTags?.length,
                        })}
                    >
                        {movableLabel}
                    </div>
                )}
                {textarea && (
                    <textarea
                        {...rest}
                        //@ts-ignore
                        ref={input}
                        className={classNames('ui-input__input', inputSize, { focus }, className, {
                            textarea,
                        })}
                        value={value}
                        //@ts-ignore
                        onFocus={onInputFocus}
                        //@ts-ignore
                        onBlur={onInputBlur}
                        disabled={disabled}
                        placeholder={label ? '' : placeholder}
                    />
                )}
                {suffixIcon && (
                    <aside
                        className={classNames(
                            'ui-input__icon suffix',
                            inputSize,
                            { move: feedback },
                            { focus },
                            {
                                interactive: !!onSuffixIconClick,
                            },
                        )}
                        onClick={onSuffixIconClick}
                    >
                        {suffixIcon}
                    </aside>
                )}
                {type === 'password' && (
                    <div
                        className={classNames('eye_icon', { left: feedback })}
                        onClick={() => {
                            setOpenEye((prev) => !prev);
                        }}
                    >
                        {openEye ? <FaRegEye /> : <FaRegEyeSlash />}
                    </div>
                )}
                {feedback && (
                    <InfoIcon
                        placement='top'
                        icon={RiErrorWarningLine}
                        tooltip={feedback}
                        className='ui-input__icon warning'
                    />
                )}
            </div>
        </div>
    );
};
