import { OidcConfiguration } from '@axa-fr/react-oidc';

type Configs = {
    configuration: OidcConfiguration;
};

export const openIdProps: Configs = {
    configuration: {
        client_id: process.env.REACT_APP_OPENID_CLIENT_ID as string,
        authority: process.env.REACT_APP_OPENID_DOMAIN as string,
        service_worker_relative_url: '/OidcServiceWorker.js',
        service_worker_only: true,
        token_renew_mode: 'access_token_invalid',
        refresh_time_before_tokens_expiration_in_second: 10,
        scope: 'openid profile quantum roles permissions tenant',
        redirect_uri: window.location.origin + '/callback',
        silent_redirect_uri: window.location.origin + '/silent-callback',
    },
};
