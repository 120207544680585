import React from 'react';
import { withWrapper } from '@shared/react';
import { MainLayoutProvider, useMainLayout } from './lib';
import { Header, Sidebar } from './components';
import { AppLoader } from '@shared/UI';
import classNames from 'classnames';
import './MainLayout.scss';

export const MainLayoutContent: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { sidebarFixed, routeGroups } = useMainLayout();

    if (!routeGroups) return <AppLoader />;

    return (
        <>
            <Sidebar />
            <main className={classNames('widget-main-layout', { sidebarFixed })}>
                <Header />
                <div className='widget-main-layout__content'>{children}</div>
            </main>
        </>
    );
};

export const MainLayout = withWrapper(MainLayoutProvider)(MainLayoutContent);
