import React, { useCallback, useState } from 'react';
import { MainLayoutContext, IMainLayoutContextContext } from './MainLayoutContext';
import { useOpenId } from '@entities/OpenIDProvider';
import { getRouteGroup } from './utils/getRouteGroup';

export const MainLayoutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [sidebarFixed, setSidebarFixed] = useState<boolean>(true);

    const sideBarToggleClick = useCallback(() => {
        setSidebarFixed((prev) => !prev);
    }, []);

    const { token } = useOpenId();

    const [currentPath, setCurrentPath] = useState<string | null>(null);

    const value: IMainLayoutContextContext = {
        routeGroups: getRouteGroup(token?.role),
        sidebarFixed,
        sideBarToggleClick,
        currentPath,
        setCurrentPath,
    };

    return <MainLayoutContext.Provider value={value}>{children}</MainLayoutContext.Provider>;
};
