import { useEffect, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Authorization_AccessTokenPayload } from '@shared/api';
import { BooleanFeatures, IPermissions } from '../../interfaces';
import { mapPermissionsToBooleanObject, mapFeaturesToBooleanObject } from '../../api';
import { decodePermissions } from '@shared/api/lib/tokens/decodeToken';

interface Returned {
    token: Authorization_AccessTokenPayload;
    permissions: IPermissions | null;
    features: BooleanFeatures | null;
}

export function useClaims(): Returned {
    const { accessTokenPayload } = useOidcAccessToken() as {
        accessTokenPayload: Authorization_AccessTokenPayload;
    };

    const [features, setFeatures] = useState<BooleanFeatures | null>(null);

    useEffect(() => {
        setFeatures(mapFeaturesToBooleanObject(accessTokenPayload?.feature || null));
    }, [accessTokenPayload]);

    return {
        permissions: decodePermissions(accessTokenPayload?.permissions) || null,
        features,
        token: accessTokenPayload,
    };
}
