import React, { lazy } from 'react';
import { QuantumMenuReactCodes } from '@widgets/MainLayout/lib/MainLayoutProvider/enums';

const MainPage = lazy(() => import('@pages/MainPage'));
const MarketWatchPage = lazy(() => import('@pages/MarketWatchPage'));
const MarketDepthPage = lazy(() => import('@pages/MarketDepthPage'));
const TradeEntryPage = lazy(() => import('@pages/TradeEntryPage'));
const TradeStatusPage = lazy(() => import('@pages/TradeStatusPage'));
const TradeTemplatesPage = lazy(() => import('@pages/TradeTemplatesPage'));
const PostTradePage = lazy(() => import('@pages/PostTradePage'));
const AdminPage = lazy(() => import('@pages/AdminPage'));
const UserGroupsPage = lazy(() => import('@pages/UserGroupsPage'));
const UsersPage = lazy(() => import('@pages/UsersPage'));
const TenantsPage = lazy(() => import('@pages/TenantsPage'));
const QuantumAdminPage = lazy(() => import('@pages/QuantumAdminPage'));
const BrokerAdminPage = lazy(() => import('@pages/BrokerAdminPage'));
const UserAdminsPage = lazy(() => import('@pages/UserAdminsPage'));

export const reactComponentsBase: Record<QuantumMenuReactCodes, React.FC> = {
    [QuantumMenuReactCodes.MainPage]: MainPage,
    [QuantumMenuReactCodes.MarketWatch]: MarketWatchPage,
    [QuantumMenuReactCodes.MarketDepth]: MarketDepthPage,
    [QuantumMenuReactCodes.QTSTradeEntry]: TradeEntryPage,
    [QuantumMenuReactCodes.QTSTradeStatus]: TradeStatusPage,
    [QuantumMenuReactCodes.QTSTradeTemplates]: TradeTemplatesPage,
    [QuantumMenuReactCodes.QTSPostTrade]: PostTradePage,
    [QuantumMenuReactCodes.UserGroups]: UserGroupsPage,
    [QuantumMenuReactCodes.UserAdmins]: UserAdminsPage,
    [QuantumMenuReactCodes.Users]: UsersPage,
    [QuantumMenuReactCodes.Admin]: AdminPage,
    [QuantumMenuReactCodes.Broker]: TenantsPage,
    [QuantumMenuReactCodes.BrokerAdmin]: BrokerAdminPage,
    [QuantumMenuReactCodes.QuantumAdmin]: QuantumAdminPage,
};
