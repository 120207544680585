import { CSSProperties, FC } from 'react';

const ErrorIcon: FC<{ className?: string; styles?: CSSProperties }> = ({ className, styles }) => {
    return (
        <svg
            className={className}
            style={styles}
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                y='0.5'
                width='40'
                height='40'
                rx='8'
                fill='#974943'
            />
            <path
                d='M24.2427 14.844L19.9998 19.086L15.7577 14.844L14.3438 16.258L18.5857 20.5L14.3438 24.742L15.7577 26.156L19.9998 21.914L24.2427 26.156L25.6568 24.742L21.4148 20.5L25.6568 16.258L24.2427 14.844Z'
                fill='white'
            />
        </svg>
    );
};

export default ErrorIcon;
