import { SelectOption } from '../../interfaces';

export const flattenOptions = (options: SelectOption[]): SelectOption[] => {
    const result: SelectOption[] = [];

    const traverse = (option: SelectOption) => {
        result.push({ label: option.label, value: option.value });
        if (option.children) {
            option.children.forEach(traverse);
        }
    };

    options.forEach(traverse);
    return result;
};
