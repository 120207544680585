import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectOption, SelectProps } from './interfaces';
import { AiFillCaretDown } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';

import { Dropdown, useDropdown } from '../Dropdown';
import { SelectDropdown } from '../SelectDropdown';
import { ClearButton } from '../ClearButton';
import { Loader } from '../Loader';
import { Input } from '../Input';
import classNames from 'classnames';
import './Select.scss';
import { MultiSelectDropdown } from '../MultiSelectDropdown/MultiSelectDropdown';
import { flattenOptions } from './lib/utils/flattenOptions';

export const Select: React.FC<SelectProps> = (props) => {
    const {
        dropdownPlacement,
        dropdownClassName,
        currentValue,
        options,
        onSelect,
        wrapperClassName,
        inputWrapperClassName,
        className,
        optionTitle = true,
        allowSearch = false,
        onSearch,
        allowClear = false,
        hideOnSelect = true,
        disabled,
        loading = false,
        value: _,
        onChange: __,
        onBlur,
        multiple = false,
        name,
        noDataLabel = 'Data is Empty',
        handleParentSelect,
        fullValue,
        ...rest
    } = props;

    const { dropdownShown, dropDownToggle, dropdownOptions, setDropdownShown, onDropdown } = useDropdown();

    const [inputValue, setInputValue] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (options.length) {
            const singleOption = options.find((option) => option.value === currentValue);
            if (Array.isArray(currentValue)) {
                if (isFirstRender.current) {
                    isFirstRender.current = false;
                    const localOptions = fullValue || flattenOptions(options);
                    const value = currentValue
                        .map((item) => localOptions?.find((test) => test.value === item))
                        .filter((option): option is SelectOption => Boolean(option));

                    value.length && setSelectedOptions(value);
                    const oneOption = options.find((item) => item.value === value);
                    onSelect(currentValue, oneOption!);
                } else if (currentValue) {
                    if (singleOption) {
                        onSelect(currentValue, singleOption);
                    }
                }
            } else {
                singleOption && isFirstRender.current && onSelect(currentValue, singleOption);
                isFirstRender.current = false;
            }
        }
    }, [options]);

    const onInputBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        (event) => {
            onBlur?.(event);
            if (!onDropdown) setDropdownShown(false);
        },
        [onBlur, onDropdown],
    );

    const onWrapperClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
        if (!dropdownShown && !disabled) dropDownToggle();
    }, [dropDownToggle, dropdownShown, disabled]);

    const onInputChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        ({ target: { value } }) => {
            if (allowSearch) {
                setInputValue(value);
                onSearch?.(value);
            }
        },
        [allowSearch, onSearch],
    );

    const currentOptionLabel = useMemo(() => {
        if (multiple) {
            return;
        } else {
            const findLabel = (options: SelectOption[]): string | null => {
                for (const option of options) {
                    if (option.value === currentValue) {
                        return option.label;
                    }
                    if (option.children) {
                        const label = findLabel(option.children);
                        if (label) {
                            return label;
                        }
                    }
                }
                return null;
            };
            return findLabel(options) || '';
        }
    }, [currentValue, options, multiple]);

    const removeTag = (selectedOption: SelectOption) => {
        setSelectedOptions((prev) => {
            let newSelectedOptions;
            newSelectedOptions = prev.filter((option) => option.value !== selectedOption.value);
            onSelect(
                newSelectedOptions.map((item) => item.value),
                selectedOption,
            );
            return newSelectedOptions;
        });
    };

    const onSelectHandler = useCallback(
        (selectedOption: SelectOption) => {
            if (selectedOption.children) {
                handleParentSelect?.(selectedOption.value);
                return;
            }
            if (multiple) {
                setSelectedOptions((prev) => {
                    let newSelectedOptions;
                    if (prev.find((option) => option.value === selectedOption.value)) {
                        newSelectedOptions = prev.filter((option) => option.value !== selectedOption.value);
                    } else {
                        newSelectedOptions = [...prev, selectedOption];
                    }
                    onSelect(
                        newSelectedOptions.map((option) => option.value),
                        //@ts-ignore
                        newSelectedOptions,
                    );
                    return newSelectedOptions;
                });
            } else {
                const findSelectedOption = (options: SelectOption[]): SelectOption | null => {
                    for (const option of options) {
                        if (option.value === selectedOption.value) {
                            return option;
                        }
                        if (option.children) {
                            const subOption = findSelectedOption(option.children);
                            if (subOption) {
                                return subOption;
                            }
                        }
                    }
                    return null;
                };

                const optionToSelect = findSelectedOption(options);

                if (optionToSelect && !optionToSelect.children) {
                    onSelect(optionToSelect.value, optionToSelect);
                    setInputValue('');
                    if (hideOnSelect && dropdownShown) {
                        dropDownToggle();
                    }
                }
            }
        },
        [onSelect, options, hideOnSelect, dropdownShown, dropDownToggle, multiple],
    );

    const clearShowCondition = useMemo(() => {
        return !disabled && allowClear && currentValue !== null && currentValue !== undefined;
    }, [currentValue, allowClear, disabled]);

    const onClear = useCallback<MouseEventHandler>(
        (event) => {
            if (clearShowCondition) {
                event?.stopPropagation();
                onSelect(null, {
                    value: null,
                    label: '',
                });
            }
        },
        [clearShowCondition, onSelect],
    );

    return (
        <div
            className={classNames('ui-select', wrapperClassName, {
                search: allowSearch,
            })}
        >
            <Input
                {...rest}
                autoComplete='off'
                value={currentOptionLabel || inputValue || ''}
                selectedTags={selectedOptions}
                onChange={onInputChange}
                removeTag={removeTag}
                wrapperClassName={inputWrapperClassName}
                onWrapperClick={onWrapperClick}
                suffixIcon={
                    loading ? (
                        <Loader className='ui-select__loader' />
                    ) : clearShowCondition ? (
                        <ClearButton />
                    ) : (
                        <IoIosArrowDown
                            size={17}
                            className={classNames('ui-select__caret', {
                                active: dropdownShown,
                            })}
                        />
                    )
                }
                type='select'
                onSuffixIconClick={onClear}
                disabled={disabled}
                className={classNames(
                    'ui-select__input',
                    {
                        ['input-not-caret']: !allowSearch,
                    },
                    {
                        multiple,
                    },
                    className,
                )}
                onBlur={onInputBlur}
            />
            <Dropdown
                className={classNames({ multiLevel: options.some((item) => item.children?.length) })}
                multiple={multiple}
                dropdownPlacement={dropdownPlacement}
                dropdownOptions={dropdownOptions}
            >
                {multiple ? (
                    <MultiSelectDropdown
                        loading={loading}
                        options={options}
                        currentValue={currentValue}
                        onSelect={onSelectHandler}
                        optionTitle={optionTitle}
                        className={classNames('ui-select-dropdown-select', {
                            multiLevel: options.some((item) => item.children?.length),
                        })}
                    />
                ) : (
                    <SelectDropdown
                        noDataLabel={noDataLabel}
                        options={options}
                        currentValue={currentValue}
                        onSelect={onSelectHandler}
                        optionTitle={optionTitle}
                        className={classNames('ui-select-dropdown-select', dropdownClassName)}
                    />
                )}
            </Dropdown>
        </div>
    );
};
