import React, { MouseEventHandler, useCallback, useId } from 'react';
import { TableActionIconProps } from './interfaces';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip';
import './TableActionIcon.scss';

export const TableActionIcon: React.FC<TableActionIconProps> = (props) => {
    const { Icon, onClick, className, tooltip, isActive = true } = props;

    const id = useId();

    const clickHandler = useCallback<MouseEventHandler>(
        (event) => {
            event?.stopPropagation();
            if (isActive) onClick(event);
        },
        [isActive, onClick],
    );

    return (
        <span className={classNames('ui-table-action-icon', { active: isActive }, className)}>
            <Icon
                data-tooltip-id={id}
                onClick={clickHandler}
            />
            {tooltip && (
                <Tooltip
                    id={id}
                    place='top'
                    content={tooltip as string}
                />
            )}
        </span>
    );
};
