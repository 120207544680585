export const colorBase = {
    primary: {
        ['qts-dark']: '#0A68B1',
        ['qts-light']: '#0A68B1',
        ['quantum-dark']: '#0A68B1',
        ['quantum-light']: '#0A68B1',
    },
    gray: {
        ['qts-dark']: '#EAEAEA',
        ['qts-light']: '#EAEAEA',
        ['quantum-dark']: '#EAEAEA',
        ['quantum-light']: '#EAEAEA',
    },
};
