import { ReactNode } from 'react';

export function formatCamelCaseString(str: string | ReactNode) {
    if (typeof str !== 'string') {
        return str;
    }
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Split camelCase
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
}
