import { CSSProperties, FC } from 'react';

const SuccessIcon: FC<{ className?: string; styles?: CSSProperties }> = ({ className, styles }) => {
    return (
        <svg
            className={className}
            style={styles}
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='40'
                height='40'
                rx='8'
                fill='#6EA18C'
            />
            <path
                d='M17.5 22.7325L14.207 19.4395L12.793 20.8535L17.5 25.5605L27.207 15.8535L25.793 14.4395L17.5 22.7325Z'
                fill='white'
            />
        </svg>
    );
};

export default SuccessIcon;
