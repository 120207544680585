import React from 'react';
import { Preloader } from '../Preloader';
import './AppLoader.scss';

export const AppLoader: React.FC = () => {
    return (
        <Preloader loading>
            <div className='ui-app-loader' />
        </Preloader>
    );
};
