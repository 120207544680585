import { QuantumTradeStatusesTemplateDto } from '../interfaces';
import { generateMockListResult } from '../../../lib';
import { mockTradeStatusFields } from './tradeStatusMockFields';

interface IOptions {
    page: number;
    pageSize: number;
    totalCount?: number;
}

export const getQuantumTradeStatusesMockTemplates = (options: IOptions) => {
    const { page = 0, pageSize = 10, totalCount = 51 } = options;

    return generateMockListResult<QuantumTradeStatusesTemplateDto>({
        fields: mockTradeStatusFields,
        page,
        pageSize,
        totalCount,
    });
};
