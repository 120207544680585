import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillInfoCircle, AiFillExclamationCircle } from 'react-icons/ai';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { NotifyType } from '../../interfaces';
import ErrorIcon from '@shared/icons/ErrorIcon';
import SuccessIcon from '@shared/icons/SuccessIcon';

export const iconBase: Record<NotifyType, React.ReactNode> = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
    warning: <BsFillExclamationTriangleFill className='feature-notify-notification__icon warning' />,
    info: <AiFillInfoCircle className='feature-notify-notification__icon info' />,
};
