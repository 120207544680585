import { useMainLayout } from '../../../../lib';
import { useCallback, useEffect, useState } from 'react';
import { IRouteGroup } from '../../../../api';

interface Returned {
    sidebarFixed: boolean;
    sidebarShown: boolean;
    linksShown: boolean;
    sideBarToggleClick: () => void;
    sidebarMouseLeave: () => void;
    sidebarMouseOver: () => void;
    routeGroups: IRouteGroup[] | null;
}

export function useSidebar(): Returned {
    const { sidebarFixed, sideBarToggleClick, routeGroups } = useMainLayout();

    const [sidebarShown, setSidebarShown] = useState<boolean>(true);
    const [linksShown, setLinksShown] = useState<boolean>(true);

    const sidebarMouseLeave = useCallback(() => {
        if (!sidebarFixed) setSidebarShown(false);
    }, [sidebarFixed]);

    const sidebarMouseOver = () => {
        setSidebarShown(true);
    };

    useEffect(() => {
        if (sidebarShown) {
            setTimeout(() => {
                setLinksShown(true);
            }, 150);
        } else {
            setLinksShown(false);
        }
    }, [sidebarShown]);

    return {
        sidebarFixed,
        sidebarShown,
        linksShown,
        sideBarToggleClick,
        sidebarMouseLeave,
        sidebarMouseOver,
        routeGroups,
    };
}
