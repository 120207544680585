import React from 'react';
import { PreloaderProps } from './interfaces';
import classnames from 'classnames';
import { Loader } from '@shared/UI';
import './Preloader.scss';

export const Preloader: React.FC<React.PropsWithChildren<PreloaderProps>> = (props) => {
    const { loading = false, children, style, contentWrapperClassName, wrapperClassName, loaderBoxClassName } = props;

    return (
        <div className={classnames('ui-preloader', wrapperClassName)}>
            <div
                className={contentWrapperClassName}
                style={style}
            >
                {children}
            </div>
            <div
                className={classnames('ui-preloader__loader', loaderBoxClassName, {
                    shown: loading,
                })}
            >
                <aside
                    className={classnames('ui-preloader__inner', {
                        shown: loading,
                    })}
                />
                <Loader />
            </div>
        </div>
    );
};
