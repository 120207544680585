import { TimeSelectorTimeOfDay, TimeSelectorHoursFormat } from '../interfaces';

export function getStringItem(item: number): string {
    let additionalZero = '';
    if (item < 10) additionalZero = '0';
    return `${additionalZero}${item}`;
}

function generateItems({ max, step }: { max: number; step: number }): string[] {
    const returned: string[] = [];
    let element = 0;
    while (element < max) {
        returned.push(getStringItem(element));
        element += step;
    }
    return returned;
}

export function getHourItems({
    hoursFormat,
    hourStep,
}: {
    hoursFormat: TimeSelectorHoursFormat;
    hourStep: number;
}): string[] {
    return generateItems({
        max: hoursFormat,
        step: hourStep,
    });
}

export function getMinuteItems({ step }: { step: number }): string[] {
    return generateItems({ max: 60, step });
}

export function getHourFormatItems(): TimeSelectorTimeOfDay[] {
    return ['AM', 'PM'];
}
