import { useWorkerFactory } from 'monaco-editor-wrapper/workerFactory';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { App } from '@app';

// eslint-disable-next-line react-hooks/rules-of-hooks
useWorkerFactory({
    ignoreMapping: true,
    workerLoaders: {
        editorWorkerService: () => {
            return new Worker(new URL('/qtdl/workers/editorWorker-es.js', window.location.href), { type: 'module' });
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>,
    <App />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
