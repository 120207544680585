import React from 'react';
import { TimeSelectorProps } from './interfaces';
import { useTimeSelector } from './useTimeSelector';
import { AiOutlineCheck } from 'react-icons/ai';
import classNames from 'classnames';
import './TimeSelector.scss';

export const TimeSelector: React.FC<TimeSelectorProps> = (props) => {
    const { currentValue, hoursFormat, mode, ...rest } = props;

    const { items, onOptionClick } = useTimeSelector({
        ...rest,
        hoursFormat,
        mode,
    });

    return (
        <div className='ui-time-selector'>
            {items.map((item) => {
                let display = item;
                if (item === '00' && mode === 'hour' && hoursFormat === 12) display = '12';
                return (
                    <span
                        key={item}
                        onClick={onOptionClick(item)}
                        className={classNames('ui-time-selector__option', 'ui-select-dropdown__option', {
                            selected: item === currentValue,
                        })}
                    >
                        {display} <AiOutlineCheck />
                    </span>
                );
            })}
        </div>
    );
};
