import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '../Input';
import { type TextInputProps } from './interfaces';

export function TextInput<T extends string = string>(props: TextInputProps<T>): React.ReactElement {
    const { value, validate, onChange, onChangeValue, ...rest } = props;

    const [feedback, setFeedback] = useState<string>();
    const [lastValidatedValue, setLastValidatedValue] = useState<T>();

    const doValidate = useCallback(
        (value: T | undefined) => {
            if (!validate || value === lastValidatedValue) {
                return;
            }
            const feedback = validate(value);
            if (typeof feedback === 'object') {
                feedback.then((result) => setFeedback((result && result) || undefined));
            } else {
                setFeedback((feedback && feedback) || undefined);
            }

            setLastValidatedValue(value);
        },
        [validate, lastValidatedValue],
    );

    const onInputChange = useCallback(
        (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
            doValidate(changeEvent.target.value as T);

            onChange && onChange(changeEvent);
            onChangeValue && onChangeValue(changeEvent.target.value as T, changeEvent);
        },
        [onChange, onChangeValue, doValidate],
    );

    useEffect(() => {
        doValidate(value);
    }, [value, doValidate]);

    return (
        <Input
            {...rest}
            type='text'
            value={value}
            onChange={onInputChange}
            feedback={feedback}
        />
    );
}
