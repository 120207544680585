import React from 'react';
import { TableCell } from '../TableCell';
import { TableCellData, TableRowData } from '../../interfaces';
import classNames from 'classnames';
import './TableRow.scss';

interface TableRowProps {
    isHeader?: boolean;
    dataSet: TableCellData[];
    rowData?: TableRowData;
    className?: string;
    vertical?: boolean;
}

export const TableRow: React.FC<TableRowProps> = (props) => {
    const { isHeader = false, dataSet, rowData = {}, className, vertical = false } = props;
    return (
        <tr
            {...rowData}
            className={classNames(
                'ui-simple-table-row',
                rowData.className,
                { hover: rowData.hover || !!rowData.onClick },
                { clickable: !!rowData.onClick },
            )}
        >
            {dataSet.map((cellData, index) => {
                return (
                    <TableCell
                        headerIcon={cellData.headerIcon}
                        onHeaderClick={cellData.onHeaderClick}
                        className={classNames(className, { verticalHeader: isHeader && vertical })}
                        key={index}
                        cellData={cellData}
                        isHeader={isHeader && !vertical}
                    />
                );
            })}
        </tr>
    );
};
