import { AuthorizationAPI } from './AuthorizationAPI/AuthorizationAPI';
import { QuantumAPI } from './QuantumAPI/Quantum';

export class ServiceAPI {
    static get authorization() {
        return AuthorizationAPI.getMethods();
    }

    static get settings() {
        return QuantumAPI.getMethods();
    }
}
