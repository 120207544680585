export const VERSION = '0.1.0';

export interface TradeEntryMessage {
    $type: string;
    version: string;
}

export namespace TradeEntryMessage {
    export function is(item: unknown): item is TradeEntryMessage {
        const message = item as TradeEntryMessage;
        return (
            typeof message === 'object' &&
            message &&
            typeof message.version === 'string' &&
            typeof message.$type === 'string'
        );
    }
}

export interface ErrorMessage extends TradeEntryMessage {
    $type: 'error';
    message: string;
}

export namespace ErrorMessage {
    export function create(message: string): ErrorMessage {
        return {
            version: VERSION,
            $type: 'error',
            message,
        };
    }
    export function is(message: unknown): message is ErrorMessage {
        return TradeEntryMessage.is(message) && message.$type === 'error';
    }
}

/**
 * Request message
 */
export interface RequestMessage extends TradeEntryMessage {
    /**
     * The request id.
     */
    id: number | string;
    $type: 'request';

    /**
     * The method to be invoked.
     */
    method: string;

    /**
     * The method's params.
     */
    params?: unknown[];
}

export namespace RequestMessage {
    export function create<P extends [], R>(msgType: RequestType<P, R>, id: number | string): RequestMessage;
    export function create<P extends unknown[], R>(
        msgType: RequestType<P, R>,
        id: number | string,
        params: P,
    ): RequestMessage;
    export function create<P extends unknown[], R>(
        msgType: RequestType<P, R>,
        id: number | string,
        params?: P,
    ): RequestMessage {
        return {
            id,
            method: msgType.method,
            $type: 'request',
            version: VERSION,
            params,
        };
    }
    export function is(message: unknown): message is RequestMessage {
        return TradeEntryMessage.is(message) && message.$type === 'request';
    }
}

export interface ResponseMessage extends TradeEntryMessage {
    /**
     * The original request id.
     */
    id: number | string;
    $type: 'response';
    data: unknown;
}

export namespace ResponseMessage {
    export function create(id: number | string, data: unknown): ResponseMessage {
        return {
            $type: 'response',
            version: VERSION,
            id,
            data,
        };
    }
    export function is(message: unknown): message is ResponseMessage {
        return TradeEntryMessage.is(message) && message.$type === 'response';
    }
}

export interface ResponseErrorMessage extends TradeEntryMessage {
    /**
     * The original request id.
     */
    id: number | string;
    $type: 'response-error';
    message: string;
}

export namespace ResponseErrorMessage {
    export function create(id: number | string, message: string): ResponseErrorMessage {
        return {
            $type: 'response-error',
            version: VERSION,
            id,
            message,
        };
    }
    export function is(message: unknown): message is ResponseErrorMessage {
        return TradeEntryMessage.is(message) && message.$type === 'response-error';
    }
}

export interface NotificationMessage<D = unknown> extends TradeEntryMessage {
    $type: 'notification';

    /**
     * The method to be invoked.
     */
    method: string;

    /**
     * The method's params.
     */
    data?: D;
}

export namespace NotificationMessage {
    export function create<D extends []>(msgType: NotificationType<D>): NotificationMessage;
    export function create<D>(msgType: NotificationType<D>, data: D): NotificationMessage;
    export function create<D>(msgType: NotificationType<D>, data?: D): NotificationMessage {
        return {
            method: msgType.method,
            $type: 'notification',
            version: VERSION,
            data: data,
        };
    }
    export function is(message: unknown): message is NotificationMessage {
        return TradeEntryMessage.is(message) && message.$type === 'notification';
    }
}

export interface TradeEntryMessageDescriptor {
    method: string;
}

export class AbstractMessageType implements TradeEntryMessageDescriptor {
    constructor(public method: string) {}
}

export class RequestType<P extends unknown[], R> extends AbstractMessageType {
    public readonly _?: ['request', P, R];
}

export class NotificationType<D> extends AbstractMessageType {
    public readonly _?: ['notification', D, void];
}
