import { ReactHOC } from '@shared/react';
import { OidcProvider, OidcSecure } from '@axa-fr/react-oidc';
import { InfoComponent } from './components';
import { openIdProps } from './openIdProps';

export const withOIDCProvider: ReactHOC = (Component) => {
    return (props) => {
        return (
            <OidcProvider
                {...openIdProps}
                callbackSuccessComponent={() => <InfoComponent type='success' />}
                authenticatingComponent={() => <InfoComponent type='authenticating' />}
                loadingComponent={() => <InfoComponent type='loading' />}
                serviceWorkerNotSupportedComponent={() => <InfoComponent type='unsupported' />}
                authenticatingErrorComponent={() => <InfoComponent type='error' />}
                sessionLostComponent={() => <InfoComponent type='session-lost' />}
            >
                <OidcSecure>
                    <Component {...props} />
                </OidcSecure>
            </OidcProvider>
        );
    };
};
