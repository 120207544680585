import { General } from '../General';
import { TradeEntry } from './TradeEntry/TradeEntry';
import { TradeStatus } from './TradeStatus/TradeStatus';
import { TradeTemplates } from './TradeTemplates/TradeTemplates';
import { UserGroups } from './UserGroups/UserGroups';
import { Users } from './Users/Users';
import { Brokers } from './Brokers/Brokers';
import { LimitTypes } from './LimitTypes/LimitTypes';
import { SecurityTypes } from './SecurityTypes/SecurityTypes';
import { Admins } from './Admins/Admins';
import { OrderTypes } from './OrderTypes/OrderTypes';
import { OrderFormatTypes } from './OrderFormatTypes/OrderFormatTypes';
import { Exchanges } from './Exchanges/Exchanges';
import { BrokerAdmins } from './BrokerAdmins/BrokerAdmins';
import { UserAdmins } from './UserAdmins/UserAdmins';
import { Markets } from './Markets/Markets';
import { AggregateLimits } from './AggregateLimits/AggregateLimits';
import { OrderSides } from './OrderSides/OrderSides';
import { WatchLists } from './WatchLists/WatchLists';

export class QuantumAPI {
    private static _basePath = General.getAuthorizationUrl();
    private static _watchListServicePath = General.getWatchListServiceUrl();

    public static getMethods() {
        return {
            tradeEntry: TradeEntry.connectWS,
            tradeTemplates: TradeTemplates.getMethods(QuantumAPI._basePath),
            tradeStatus: TradeStatus.getMethods(QuantumAPI._basePath),
            userGroups: UserGroups.getMethods(QuantumAPI._basePath),
            users: Users.getMethods(QuantumAPI._basePath),
            brokers: Brokers.getMethods(QuantumAPI._basePath),
            limitTypes: LimitTypes.getMethods(QuantumAPI._basePath),
            securityTypes: SecurityTypes.getMethods(QuantumAPI._basePath),
            admins: Admins.getMethods(QuantumAPI._basePath),
            orderTypes: OrderTypes.getMethods(QuantumAPI._basePath),
            orderFormatTypes: OrderFormatTypes.getMethods(QuantumAPI._basePath),
            orderSides: OrderSides.getMethods(QuantumAPI._basePath),
            exchanges: Exchanges.getMethods(QuantumAPI._basePath),
            brokerAdmins: BrokerAdmins.getMethods(QuantumAPI._basePath),
            userAdmins: UserAdmins.getMethods(QuantumAPI._basePath),
            markets: Markets.getMethods(QuantumAPI._basePath),
            aggregateLimits: AggregateLimits.getMethods(QuantumAPI._basePath),
            watchLists: WatchLists.getMethods(QuantumAPI._watchListServicePath),
        };
    }
}
