export enum QuantumMenuReactCodes {
    MainPage = 'MainPage',
    UserGroups = 'UserGroups',
    UserAdmins = 'UserAdmins',
    MarketWatch = 'MarketWatch',
    MarketDepth = 'MarketDepth',
    QTSTradeEntry = 'QTSTradeEntry',
    QTSTradeStatus = 'QTSTradeStatus',
    QTSTradeTemplates = 'QTSTradeTemplates',
    QTSPostTrade = 'QTSPostTrade',
    QuantumAdmin = 'QuantumAdmin',
    Admin = 'Admin',
    BrokerAdmin = 'BrokerAdmin',
    Broker = 'Broker',
    Users = 'Users',
}
