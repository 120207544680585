import { Api } from '@shared/api/Api';
import { IExchangesList } from './interfaces';
import { QuantumExchangesGetQuery } from './query';
import { UniqueId } from '@shared/api/interfaces';

export class Exchanges {
    private static _basePath = '/Exchange';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${Exchanges._basePath}`;
        return {
            async get(query: QuantumExchangesGetQuery) {
                const path = '/GetAll';
                const data = await Api.get<IExchangesList>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
            async getById(id: UniqueId) {
                const path = '/GetById/';
                return await Api.get<any>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
