import { Api } from '@shared/api/Api';
import { UniqueId } from '@shared/api/interfaces';
import { IBrokerAdminDTO, IBrokerAdminsList } from './interfaces';
import { QuantumBrokerAdminsGetQuery, IBrokerAdminsCreate } from './interfaces';

export class BrokerAdmins {
    private static _basePath = '/BrokerAdmin';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${BrokerAdmins._basePath}`;
        return {
            async get(query?: QuantumBrokerAdminsGetQuery) {
                const path = '/GetAll';
                const data = await Api.get<IBrokerAdminsList>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
            async getById({ id }: { id: UniqueId }) {
                const path = '/GetById/';
                return await Api.get<IBrokerAdminsCreate>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async post(body: IBrokerAdminsCreate) {
                const path = '/Create';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async putById({ id }: { id: UniqueId }, body: IBrokerAdminsCreate) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async deleteById({ id }: { id: UniqueId }) {
                const path = `/Delete/`;
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
