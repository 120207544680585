import React, { useCallback } from 'react';
import { ITime, TimeSelectDropdownProps } from './interfaces';
import { getStringItem, TimeSelector, TimeSelectorTimeOfDay } from '../TimeSelector';
import './TimeSelectDropdown.scss';

export const TimeSelectDropdown: React.FC<TimeSelectDropdownProps> = (props) => {
    const {
        currentValue,
        onTimeSelect,
        hours = true,
        minutes = true,
        seconds = false,
        hoursFormat,
        hourStep,
        minuteStep,
        secondStep,
    } = props;

    const onTimeSelectHandler = useCallback<(field: keyof ITime) => (value: TimeSelectorTimeOfDay | number) => void>(
        (field) => (value) => {
            onTimeSelect((prev) => ({
                ...(prev || {
                    hour: 0,
                    minute: 0,
                    second: 0,
                }),
                [field]: value,
            }));
        },
        [onTimeSelect],
    );

    return (
        <div className='ui-time-select-dropdown'>
            {hours && (
                <TimeSelector
                    onSelect={onTimeSelectHandler('hour')}
                    currentValue={
                        currentValue?.hour !== undefined && hoursFormat
                            ? getStringItem(currentValue.hour % hoursFormat)
                            : undefined
                    }
                    mode='hour'
                    hoursFormat={hoursFormat}
                    hourStep={hourStep}
                />
            )}
            {minutes && (
                <TimeSelector
                    onSelect={onTimeSelectHandler('minute')}
                    currentValue={currentValue?.minute !== undefined ? getStringItem(currentValue.minute) : undefined}
                    mode='minute'
                    minuteStep={minuteStep}
                />
            )}
            {seconds && (
                <TimeSelector
                    onSelect={onTimeSelectHandler('second')}
                    currentValue={currentValue?.second !== undefined ? getStringItem(currentValue.second) : undefined}
                    mode='second'
                    secondStep={secondStep}
                />
            )}
            {hoursFormat === 12 && (
                <TimeSelector
                    onSelect={onTimeSelectHandler('timeOfDay')}
                    currentValue={currentValue?.timeOfDay}
                    mode='time-of-day'
                />
            )}
        </div>
    );
};
