import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useKeyDown } from '@shared/react';

interface Options {
    closingBlocked?: boolean;
    close: () => void;
    onClose?: () => void;
    outTransitionTime: number;
}

interface Returned {
    shown: boolean;
    setClosingBlocked: (blocked: boolean) => void;
    attemptedBlockedClosure: boolean;
    resetAttemptedBlockedClosure: () => void;
    onBodyClick: MouseEventHandler;
    closeClick: () => void;
    doClose: (force?: boolean) => void;
}

export function useModalRender(options: Options): Returned {
    const { close, onClose, outTransitionTime } = options;

    const [shown, setShown] = useState<any>(null);

    const [closingBlocked, setClosingBlocked] = useState<boolean>();

    const [attemptedBlockedClosure, setAttemptedClosure] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => setShown(true), 50);
    }, []);

    const resetAttemptedBlockedClosure = useCallback(() => {
        setAttemptedClosure(false);
    }, []);

    const onBodyClick: MouseEventHandler = (event) => {
        event?.stopPropagation();
    };

    const doClose = useCallback(
        (force = false) => {
            if (!force && closingBlocked) {
                setAttemptedClosure(true);
                return;
            }
            setShown(false);
            setTimeout(() => {
                onClose?.();
                close();
            }, outTransitionTime);
        },
        [closingBlocked, close, onClose, outTransitionTime],
    );

    const closeClick = useCallback(() => {
        doClose();
    }, [doClose]);

    useKeyDown({
        onDown: closeClick,
        keyName: 'Escape',
    });

    return {
        shown,
        setClosingBlocked,
        attemptedBlockedClosure,
        resetAttemptedBlockedClosure,
        onBodyClick,
        closeClick,
        doClose,
    };
}
