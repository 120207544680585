import React from 'react';
import { HeaderTabs } from './components';
import './Header.scss';

export const Header: React.FC = () => {
    return (
        <header className='widget-main-layout-header'>
            <HeaderTabs />
        </header>
    );
};
