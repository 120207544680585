import React from 'react';
import { Button, ErrorBlock } from '@shared/UI';

export const BlockSomethingWentWrong: React.FC = () => {
    const onStartAgain = () => {
        location.href = window.location.origin || '';
    };

    const body = (
        <>
            <h2>Oh, no! Something went wrong. We are already working on the problem</h2>
            <Button onClick={onStartAgain}>Let's try that again</Button>
        </>
    );

    return (
        <ErrorBlock
            header='Something went wrong!'
            body={body}
        />
    );
};
