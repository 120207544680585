import { Api } from '../../Api';
import { IUserAdminFullDTO, IUserAdminsList } from './interfaces';
import { QuantumUserGroupsPutBody } from './body';
import {
    QuantumUserGroupsGetByIdParams,
    QuantumUserGroupsPutByIdParams,
    QuantumUserGroupsDeleteByIdParams,
} from './params';
import { QuantumUserGroupsGetQuery } from './query';

export class UserAdmins {
    private static _basePath = '/UserAdmin';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${UserAdmins._basePath}`;
        return {
            async get(query?: QuantumUserGroupsGetQuery) {
                const path = '/GetAll';
                return await Api.get<IUserAdminsList>({
                    path: `${basePath}${path}`,
                    query,
                });
            },
            async post(body: IUserAdminFullDTO) {
                const path = '/Create';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async getById({ id }: QuantumUserGroupsGetByIdParams, isMock: boolean = false) {
                const path = '/GetById/';
                return await Api.get<IUserAdminFullDTO>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async putById({ id }: QuantumUserGroupsPutByIdParams, body: IUserAdminFullDTO) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async deleteById({ id }: QuantumUserGroupsDeleteByIdParams) {
                const path = '/Delete/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
