import React from 'react';
import { useModalGeneralForPublic, Returned } from '../useModalGeneralForPublic';
import { ModalProps } from '@features/Modal';

export const useDrawerPublic = <P extends ModalProps = any>(
    Component: React.FC<P>,
    queryName?: string,
): Returned<Omit<P, keyof ModalProps>> => {
    const { open } = useModalGeneralForPublic(Component, 'drawer', queryName);
    return { open };
};
