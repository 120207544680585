import {
    NotificationMessage,
    NotificationType,
    RequestMessage,
    RequestType,
    ResponseErrorMessage,
    ResponseMessage,
    TradeEntryMessage,
} from './interfaces-protocol';
import { Deferred } from './util';

export interface WebSocketProxy {
    url: string;
    readyState: number;
    onmessage?: (message: TradeEntryMessage) => void;
    onnotification?: (message: NotificationMessage) => void;
    send: (message: TradeEntryMessage) => void;
    onclose?: (event: CloseEvent) => void;
    close: (code?: number, reason?: string) => void;
}

interface UnresolvedRequest {
    id: string | number;
    response: Deferred<unknown>;
    dispose(): void;
}

export const TradeEntry = {
    connectWS: async (
        basePath: string,
        payload: { [key: string]: string } = {},
        maxRetries = 4,
        pingInterval = 30000,
    ) => {
        return new TradeEntryConnection().connect(basePath, payload, maxRetries, pingInterval);
    },
};

async function createSocket(
    basePath: string,
    payload: { [key: string]: string } = {},
    pingInterval = 30000,
): Promise<WebSocketProxy> {
    return new Promise<WebSocketProxy>((resolve, reject) => {
        let unresolved = true;
        let socket: WebSocket;
        try {
            socket = new WebSocket(`${basePath}`, 'json');
        } catch (error) {
            unresolved && reject(error);
            return;
        }

        // don't provide direct access to the WebSocket object itself, but handout a proxy object satisfying parts of the WebSocket interface
        const proxy: WebSocketProxy = {
            get url() {
                return socket.url;
            },
            get readyState() {
                return socket.readyState;
            },
            send: (message: TradeEntryMessage) => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify(message));
                } else {
                    console.error('TradeEntry websocket: readyState is not OPEN');
                }
            },
            close: socket.close,
        };

        socket.onopen = (_event) => {
            console.log('TradeEntry webSocket connection opened');
            // retries = 0;
            // startPingPong();
            resolve(proxy);
            unresolved = false;
        };

        socket.onerror = (event) => {
            console.error('TradeEntry webSocket error: ' + socket.readyState, event);
            // stopPingPong();
            if (unresolved) {
                reject(event);
            } else {
                throw event;
            }
        };

        socket.onmessage = (event) => {
            console.log(
                'TradeEntry websocket message from server:',
                typeof event.data === 'string' && event.data.length > 200
                    ? event.data.slice(0, 200) + '...'
                    : event.data,
            );
            if (socket.protocol !== 'json') {
                console.log('Invalid protocol: ' + socket.protocol);
            } else {
                let data: TradeEntryMessage;

                try {
                    data = JSON.parse(event.data);
                } catch (error) {
                    console.error('Invalid JSON message:', error);
                    return;
                }

                // if (data.$type === 'ping') {
                //     isPongReceived = true;

                // } else
                if (proxy.onmessage) {
                    proxy.onmessage(data);
                }
            }
        };

        socket.onclose = (event) => {
            console.log('TradeEntry webSocket connection closed:', event);
            // stopPingPong();
            if (proxy.onclose) {
                proxy.onclose(event);
            }
        };
    });
}

export class TradeEntryConnection {
    private requestMap = new Map<string | number, UnresolvedRequest>();
    private requestId = 1;
    private socket: WebSocketProxy | undefined;
    private closed = false;

    private notificationListeners: Map<string, Array<(message: unknown) => void>> = new Map();

    public toString() {
        return this.socket?.url ?? '<not connected>';
    }

    public async connect(
        basePath: string,
        payload: { [key: string]: string } = {},
        maxRetries = 4,
        pingInterval = 30000,
    ): Promise<this> {
        let retries = 0;

        const tryReConnect: () => Promise<WebSocketProxy> = async () => {
            if (retries < maxRetries) {
                retries += 1;
                const timeout = Math.min(1000 * Math.pow(2, retries), 30000); // Exponential backoff up to 30 seconds
                console.log(`TradeEntry websocket attempting to reconnect in ${timeout / 1000} seconds...`);
                return new Promise<WebSocketProxy>((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            const socket = await createSocket(basePath, payload);
                            attachListeners(socket);
                            retries = 0;
                            resolve(socket);
                        } catch (error) {
                            tryReConnect().then(resolve).catch(reject);
                        }
                    }, timeout);
                });
            } else {
                console.error('TradeEntry websocket: Max retries reached. Giving up.');
                return Promise.reject(new Error('TradeEntry websocket: Max retries reached. Giving up.'));
            }
        };

        const attachListeners = (socket: WebSocketProxy) => {
            this.socket = socket;
            this.socket.onmessage = this.handleMessage.bind(this);
            this.socket.onclose = (event) => {
                socket.onmessage = undefined;
                socket.onclose = undefined;
                if (!this.closed) {
                    this.socket = undefined;
                    console.error('TradeEntry websocket closed unexpectedly:', event);
                    tryReConnect();
                }
            };
        };

        try {
            await createSocket(basePath, payload).then(attachListeners);
        } catch (error) {
            await tryReConnect();
        }

        return this.checkConnected();
    }

    public close() {
        this.closed = true;
        this.socket?.close();
    }

    private checkConnected(): this {
        if (!this.socket) {
            throw new Error('TradeEntry widget not connected to server.');
        } else if (this.closed || this.socket.readyState !== WebSocket.OPEN) {
            throw new Error('TradeEntry widget connection is closed.');
        } else {
            return this;
        }
    }

    public sendRequest<P extends unknown[], R>(type: RequestType<P, R>, ...parameters: P): Promise<R> {
        if (!this.socket) {
            return Promise.reject(new Error('TradeEntry widget not connected to server.'));
        }
        const id = this.requestId++;
        const deferred = new Deferred<R>();
        const dispose = () => {
            this.requestMap.delete(id);
            clearTimeout(timeout);
            deferred.reject(new Error('Request timed out'));
        };
        const timeout = setTimeout(dispose, 600_000); // Timeout after one minute
        const relayedMessage: UnresolvedRequest = {
            id,
            response: deferred as Deferred<unknown>,
            dispose,
        };
        this.requestMap.set(id, relayedMessage);
        const message = RequestMessage.create(type, id, parameters);
        this.socket.send(message);
        return deferred.promise;
    }

    // listen to Notifications of a certain method
    public listenToNotifications<D = unknown>(type: NotificationType<D>, listener: (message: D) => void): () => void {
        // register the listener in the map for notifications
        const listeners = this.notificationListeners.get(type.method);
        if (listeners) {
            listeners.push(listener as (message: unknown) => void);
        } else {
            this.notificationListeners.set(type.method, [listener as (message: unknown) => void]);
        }

        // return a function to dispose the listener once it is no longer needed
        return () => {
            const listeners = this.notificationListeners.get(type.method);

            // 'listeners' is assumed to be defined, as it was set above for the given method
            listeners &&
                this.notificationListeners.set(
                    type.method,
                    listeners.filter((l) => l !== listener),
                );
        };
    }

    private handleMessage(message: TradeEntryMessage) {
        if (ResponseMessage.is(message) || ResponseErrorMessage.is(message)) {
            const request = this.requestMap.get(message.id);
            if (request) {
                if (ResponseMessage.is(message)) {
                    request.response.resolve(message.data);
                } else {
                    request.response.reject(message.message);
                }
                request.dispose();
            }
        } else if (NotificationMessage.is(message)) {
            this.notificationListeners.get(message.method)?.forEach((l) => l(message.data));
        }
    }
}

export const NullTradeEntryConnection = new (class extends TradeEntryConnection {
    sendRequest() {
        return Promise.reject(new Error('TradeEntry widget not connected.'));
    }
    close() {
        // no-op
    }
})();
