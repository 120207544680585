import { Api } from '@shared/api/Api';
import { IQuantumBrokerDto, IQuantumBrokerItem } from './interfaces';
import { UniqueId } from '@shared/api/interfaces';
import { ITransformedObject } from '@widgets/BrokersTable/components/lib/utils/transformData';
import { QuantumBrokersGetQuery } from './query';

export class Brokers {
    private static _basePath = '/Tenant';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${Brokers._basePath}`;
        return {
            async get(query?: QuantumBrokersGetQuery) {
                const path = '/GetAll';
                const data = await Api.get<IQuantumBrokerDto>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
            async getById({ id }: { id: UniqueId }) {
                const path = '/GetById/';
                return await Api.get<IQuantumBrokerItem>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async putById({ id }: { id: UniqueId }, body: ITransformedObject) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async post(body: ITransformedObject) {
                const path = '/Create';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async deleteById({ id }: { id: UniqueId }) {
                const path = `/Delete/`;
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
