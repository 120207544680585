import { Api } from '@shared/api/Api';
import { IAggregateLimitsList } from './interfaces';

export class AggregateLimits {
    private static _basePath = '/AggregateLimits';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${AggregateLimits._basePath}`;
        return {
            async get() {
                const path = '/Get';
                const data = await Api.get<IAggregateLimitsList>({
                    path: `${basePath}${path}`,
                });
                return data;
            },
            async getById(id: string) {
                const path = '/Get/';
                const data = await Api.get<any>({
                    path: `${basePath}${path}${id}`,
                });
                return data;
            },
        };
    }
}
