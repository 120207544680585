import React, { MouseEventHandler, useCallback, useId } from 'react';
import { CheckboxProps } from './interfaces';
import { useElement } from '../../react';
import classNames from 'classnames';
import './Checkbox.scss';

export interface ExtendedCheckboxProps extends CheckboxProps {
    type?: 'checkmark' | 'square';
    value?: boolean;
}

export const Checkbox: React.FC<ExtendedCheckboxProps> = (props) => {
    const { name, label, checked, onChange, className, disabled = false, type = 'checkmark', value } = props;

    const { target } = useElement<HTMLInputElement>();

    const id = useId();

    const checkboxChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            event.stopPropagation();
            if (!disabled) onChange?.(event);
        },
        [onChange, disabled],
    );

    return (
        <div
            className={classNames(
                'ui-checkbox',
                'non-selectable',
                { active: checked || !!value },
                { disabled },
                className,
            )}
        >
            <aside className='ui-checkbox__overlay' />
            <input
                id={id}
                ref={target}
                name={name}
                type='checkbox'
                onChange={checkboxChange}
                checked={checked || !!value}
                className={classNames('ui-checkbox__input', {
                    active: checked || !!value,
                    checkmark: type === 'checkmark',
                    square: type === 'square',
                })}
            />
            <label className='ui-checkbox__label'>{label}</label>
        </div>
    );
};
