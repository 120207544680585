import { Api } from '@shared/api/Api';
import { IMarketsFullJSONList, IMarketsList } from './interfaces';
import { QuantumMarketsGetQuery } from './query';

export class Markets {
    private static _basePath = '/Market';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${Markets._basePath}`;
        return {
            async get(query: QuantumMarketsGetQuery) {
                const path = '/GetAll';
                const data = await Api.get<IMarketsList>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
            async getFull(query: QuantumMarketsGetQuery) {
                const path = '/GetAll/Full';
                const data = await Api.get<IMarketsFullJSONList>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
        };
    }
}
