import React, { MouseEventHandler, useCallback, useState } from 'react';
import { OUT_TRANSITION_TIME } from './constants';
import { useModalRender, ModalWrapperProps } from '../../lib';
import classNames from 'classnames';
import { Close } from '../Close';
import { ExpandButton } from './ExpandButton';
import './Drawer.scss';

export const Drawer: React.FC<ModalWrapperProps> = (props) => {
    const { close, Component, props: componentProps, modalId, closeClassName } = props;

    const {
        closeClick,
        shown,
        onBodyClick,
        setClosingBlocked,
        attemptedBlockedClosure,
        resetAttemptedBlockedClosure,
        doClose,
    } = useModalRender({
        close,
        onClose: componentProps.onClose,
        outTransitionTime: OUT_TRANSITION_TIME,
    });

    const [expanded, setExpanded] = useState<boolean>(false);

    const onExtend = useCallback<MouseEventHandler>((event) => {
        event?.stopPropagation();
        setExpanded((prev) => !prev);
    }, []);

    const onClose = useCallback(() => {
        if (expanded) {
            setExpanded(false);
            setTimeout(() => closeClick(), 50);
        }
        closeClick();
    }, [closeClick, expanded]);

    return (
        <div
            className={classNames('feature-modal-drawer', { shown })}
            onMouseDown={closeClick}
        >
            <section
                className={classNames('feature-modal-drawer__body', {
                    expanded,
                })}
                onMouseDown={onBodyClick}
            >
                <Close
                    close={onClose}
                    className={closeClassName}
                />
                <ExpandButton
                    expanded={expanded}
                    onClick={onExtend}
                />
                <Component
                    {...componentProps}
                    {...{ setClosingBlocked, attemptedBlockedClosure, resetAttemptedBlockedClosure, close: doClose }}
                    id={modalId}
                />
            </section>
        </div>
    );
};
