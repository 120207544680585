import React from 'react';
import classNames from 'classnames';
import { CardProps } from './interfaces';
import './Card.scss';

export const Card: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
    const { className, ...rest } = props;

    return (
        <section
            className={classNames('ui-card', className)}
            {...rest}
        />
    );
};
