import { GetOptions, PostOptions, PutOptions, DeleteOptions, PatchOptions } from '../../interfaces';
import { getMock } from '../../../lib';
import axios, { AxiosRequestConfig } from 'axios';
import { mergeAxiosConfig } from '../mergeAxiosConfig';

interface Options<Response = void>
    extends GetOptions<Response>,
        PostOptions<Response>,
        PutOptions<Response>,
        DeleteOptions<Response>,
        PatchOptions<Response> {
    method: 'get' | 'post' | 'put' | 'delete' | 'patch';
}

export async function makeAxiosRequest<Response = void>(options: Options<Response>): Promise<Response> {
    const { method, path, body, mock, isMock, mockName, ...rest } = options;

    if (isMock || process.env.IS_ONLY_MOCK) {
        console.log('-----------------------------------------------------------------------');
        console.log(`mock ${method} request on ${path}${mockName ? ` | ${mockName}` : ''}`);
        console.log('query: ', rest.query);
        console.log('body: ', body);
        console.log('headers: ', rest.headers);
        try {
            const data = await getMock<Response>(mock?.() as Response);
            console.log('response: ', data);
            return await getMock<Response>(mock?.() as Response);
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            console.log('-----------------------------------------------------------------------');
            console.log('');
        }
    }

    const args = [path, body].filter((el) => el !== undefined) as [string, AxiosRequestConfig['data'] | undefined];
    const { data } = await axios[method]<Response>(...args, mergeAxiosConfig(rest));
    return data;
}
