import { QuantumTradeTemplatesTemplateDto } from '../interfaces';
import { generateMockListResult } from '../../../lib';
import { mockTradeTemplateFields } from './tradeTemplatesMockFields';
import { QTSTemplate, createACLForUser } from '@widgets/TradeEntry/api';

interface IOptions {
    page: number;
    pageSize: number;
    totalCount?: number;
}

export function getQuantumTradeTemplatesMockTemplates(options: IOptions) {
    const { page = 0, pageSize = 10, totalCount = 51 } = options;

    const examples = [template1, template2, template3, template4];

    const mocks = generateMockListResult<QuantumTradeTemplatesTemplateDto>({
        fields: mockTradeTemplateFields,
        page,
        pageSize,
        totalCount,
    });

    return {
        results: [...examples, ...(mocks.results ?? [])] as QuantumTradeTemplatesTemplateDto[],
        totalCount: examples.length + mocks.totalCount,
    };
}

const template1: Omit<QTSTemplate, 'name'> & Pick<QuantumTradeTemplatesTemplateDto, 'shortName'> = {
    $type: 'QTSTemplate',
    id: '1',
    shortName: 'Buy 100 shares (Single)',
    simulationRequired: false,
    order: {
        $type: 'Single',
        orderDirection: 'buy',
        amount: '100',
        amountType: 'quantity',
        conditions: [
            {
                $type: 'price',
                name: 'Example Condition',
                value: {
                    leftExpression: {
                        priceType: 'ask',
                        increment: '0',
                    },
                    relativeInstrument: {
                        relativeInstrumentType: 'security',
                        instrument: 'AAPL',
                    },
                    operator: '>',
                    rightExpression: {
                        priceType: 'ask',
                        increment: '1',
                    },
                },
                sequence: 'prior',
                conditionalType: 'while',
            },
        ],
        orderType: 'market',
        securityType: 'stock',
        timeInForce: 'gtc',
    },
};

const template2: Omit<QTSTemplate, 'name'> & Pick<QuantumTradeTemplatesTemplateDto, 'shortName'> = {
    $type: 'QTSTemplate',
    id: '2',
    shortName: 'Sell 100 shares (Single)',
    simulationRequired: true,
    order: {
        $type: 'Single',
        orderDirection: 'sell',
        amount: '100',
        amountType: 'quantity',
        conditions: [],
        orderType: 'market',
        // exchange: 'NYSE',
        securityType: 'stock',
        timeInForce: 'gtc',
    },
};

const template3: Omit<QTSTemplate, 'name'> & Pick<QuantumTradeTemplatesTemplateDto, 'shortName'> = {
    $type: 'QTSTemplate',
    id: '3',
    shortName: 'Example Pair Trade',
    simulationRequired: false,
    order: {
        $type: 'Pair',
        ratio: 1.5,
        ratioType: 'ratio',
        orderA: {
            $type: 'Single',
            conditions: [],
            orderType: 'market',
            security: 'AAPL:NYSE',
            // exchange: 'NYSE',
            orderDirection: 'buy',
            securityType: 'stock',
            timeInForce: 'gtc',
        },
        orderB: {
            $type: 'Single',
            conditions: [],
            orderType: 'market',
            // exchange: 'NYSE',
            orderDirection: 'sell',
            securityType: 'stock',
            timeInForce: 'gtc',
        },
        conditions: [],
    },
};

const template4: Omit<QTSTemplate, 'name'> & Pick<QuantumTradeTemplatesTemplateDto, 'shortName'> = {
    $type: 'QTSTemplate',
    id: '4',
    shortName: 'Non-QuickTrade List Template',
    simulationRequired: false,
    order: {
        $type: 'List',
        globalDirection: 'none',
        defaultOrderVolType: 'none',
        securities: [],
        conditions: [],
        modifier: 'gtd',
    },
};
