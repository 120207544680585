import React, { useMemo } from 'react';
import './HeaderTabs.scss';
import { useMainLayout } from '@widgets/MainLayout';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

export const HeaderTabs: React.FC = () => {
    const { routeGroups, setCurrentPath } = useMainLayout();

    const { pathname } = useLocation();

    const isSingleTab = useMemo(() => {
        return (routeGroups?.find((item) => item.path === pathname)?.tabs?.length || 0) === 1;
    }, [pathname]);

    return (
        <div className={classNames('widget-header-external-links', { none: isSingleTab })}>
            {routeGroups?.map(
                (route) =>
                    `${route.path}` === `/${pathname.split('/')[1]}` &&
                    route.tabs?.map((item, index) => (
                        <NavLink
                            key={index}
                            to={`${item.path}`}
                            className={({ isActive }) =>
                                'widget-header-external-links__link' + (pathname === item.path ? ' active' : '')
                            }
                            onClick={() => setCurrentPath(`${route.path}`)}
                        >
                            {item.name}
                        </NavLink>
                    )),
            )}
        </div>
    );
};
