import React from 'react';
import { DynamicProgressBarProps } from './interfaces';
import { useDynamicProgressBar } from './useDynamicProgressBar';
import classNames from 'classnames';
import './DynamicProgressBar.scss';

export const DynamicProgressBar: React.FC<DynamicProgressBarProps> = (props) => {
    const {
        value,
        maxValue,
        label,
        className,
        thumbClassName,
        breakPoints,
        isBadge = true,
        isRatio = true,
        isPercentage = false,
    } = props;

    const { normalizedValue, thumbColor } = useDynamicProgressBar({
        value,
        maxValue,
        breakPoints,
    });

    return (
        <div className={classNames('ui-dynamic-progress-bar', className)}>
            <div className='ui-dynamic-progress-bar__rail'>
                <div
                    className={classNames('ui-dynamic-progress-bar__thumb', thumbClassName, thumbColor)}
                    style={{
                        right: `${100 - normalizedValue}%`,
                    }}
                />
            </div>
            {isBadge && (
                <span className='ui-dynamic-progress-bar__label'>
                    {`${isPercentage ? (Math.round(value) > 100 ? 100 : Math.floor(value)) : value}${
                        isRatio && !isPercentage ? ` / ${maxValue}` : ''
                    }`}
                    {label ? ` ${label}` : ''}
                    {isPercentage ? '%' : ''}
                </span>
            )}
        </div>
    );
};
