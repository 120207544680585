import moment from 'moment';
import { QuantumTradeTemplatesTemplateDto, QuantumTradeTemplatesTemplateFullDto } from '../interfaces';
import { generateId } from '../../../../utils';
import { Field, generateRandomISOString, generateRandomLastName } from '../../../lib';

const formatTemplatesDate = (): string => {
    return moment(generateRandomISOString()).format('hh:mm A, MMM Do, YYYY');
};

const generateTemplatesId = () => generateId(); //.slice(0, 8);

export const mockTradeTemplateFields: Field<QuantumTradeTemplatesTemplateDto>[] = [
    {
        fieldName: 'id',
        value: generateTemplatesId,
    },
    {
        fieldName: 'quickTrade',
        value: 'Y',
    },
    {
        fieldName: 'shortName',
        value: generateRandomLastName,
    },
    {
        fieldName: 'creationDate',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'userId',
        value: generateId,
    },
    {
        fieldName: 'description',
        value: () => `Description for record`,
    },
    {
        fieldName: 'templateId',
        value: generateTemplatesId,
    },
    {
        fieldName: 'lastTradeDate',
        value: formatTemplatesDate,
    },
];

export const MockTemplateFullFields: Field<QuantumTradeTemplatesTemplateDto>[] = [
    {
        fieldName: 'id',
        value: generateTemplatesId,
    },
    {
        fieldName: 'quickTrade',
        value: 'Y',
    },
    {
        fieldName: 'shortName',
        value: generateRandomLastName,
    },
    {
        fieldName: 'creationDate',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'userId',
        value: generateId,
    },
    {
        fieldName: 'description',
        value: () => `Description for record`,
    },
    {
        fieldName: 'templateId',
        value: generateTemplatesId,
    },
    {
        fieldName: 'lastTradeDate',
        value: formatTemplatesDate,
    },
];
