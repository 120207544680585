import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMainLayout } from '../../../../lib';
import './SideMenu.scss';

export const SideMenu: React.FC = () => {
    // todo qts implement sidemenu
    const { setCurrentPath, routeGroups } = useMainLayout();

    return (
        <>
            {routeGroups?.map((item) => (
                <NavLink
                    to={item.path}
                    className={({ isActive }) => 'widget-main-layout-route' + (isActive ? ' active' : '')}
                    onClick={() => setCurrentPath('')}
                >
                    {item.name}
                </NavLink>
            ))}
        </>
    );
};
