import { Services } from './services';

export class General {
    public static getAuthorizationUrl() {
        return Services.authorizationServiceUrl + '/api/v1';
    }

    public static getWatchListServiceUrl() {
        return Services.watchListServiceUrl + '/api';
    }

    public static getStreamerUrl() {
        return Services.quoteStreamerUrl;
    }
}
