import {
    EAuthorization_Permissions,
    EAuthorization_Features,
    EAuthorization_Boolean,
    EAuthorization_ContactCenterType,
    EAuthorization_AdapterMode,
    EAuthorization_QuantumClientCode,
} from './enums';
import { UniqueId, Email, JSONString, Nullable } from '../interfaces';
import { OidcUserInfo } from '@axa-fr/react-oidc';

export enum User_Roles {
    QuantumSuperAdmin = 'QuantumSuperAdmin',
    QUANTUM_ADMIN = 'QUANTUM_ADMIN',
    BrokerDealerAdmin = 'BrokerDealerAdmin',
    EndUserAdmin = 'EndUserAdmin',
    EndUser = 'EndUser',
}

export interface Authorization_AccessTokenPayload {
    nbf: Nullable<number>;
    exp: Nullable<number>;
    iss: Nullable<string>;
    aud: Nullable<string>;
    client_id: Nullable<string>;
    sub: Nullable<string>;
    auth_time: Nullable<number>;
    given_name: string;
    family_name: string;
    idp: Nullable<string>;
    role: keyof typeof User_Roles;
    userId: Nullable<UniqueId>;
    feature: Nullable<EAuthorization_Features[]>;
    permissions: Nullable<string>;
    clientGroup: Nullable<UniqueId>;
    contactCenterType: Nullable<EAuthorization_ContactCenterType>;
    isQuantumUsed: Nullable<EAuthorization_Boolean>;
    quantumClientCode: Nullable<EAuthorization_QuantumClientCode>;
    timeZone: Nullable<string>;
    adapterMode: Nullable<EAuthorization_AdapterMode>;
    contactCenterDomain: Nullable<string>;
    systemSetting: Nullable<JSONString>;
    username: Nullable<Email>;
    preferred_username: Nullable<Email>;
    jti: Nullable<string>;
    sid: Nullable<string>;
    iat: Nullable<number>;
    scope: Nullable<string[]>;
    amr: Nullable<string[]>;
}

export interface QuantumUser extends OidcUserInfo {
    adapterMode: Nullable<EAuthorization_AdapterMode>;
    clientGroup: Nullable<UniqueId>;
    contactCenterDomain: Nullable<string>;
    contactCenterType: Nullable<EAuthorization_ContactCenterType>;
    quantumClientCode: Nullable<EAuthorization_QuantumClientCode>;
    quantumServer: Nullable<string>;
    isQuantumUsed: Nullable<EAuthorization_Boolean>;
    systemSetting: Nullable<JSONString>;
    timeZone: Nullable<string>;
    userId: Nullable<UniqueId>;
    username: Nullable<string>;
}
