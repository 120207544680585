import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { TimeSelectProps } from './interfaces';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Dropdown, useDropdown } from '../Dropdown';
import { ClearButton } from '../ClearButton';
import { Input } from '../Input';
import { TimeSelectDropdown, ITime } from '../TimeSelectDropdown';
import classNames from 'classnames';
import { formatTime, transformTicksToTime, transformTimeToTicks } from './lib';
import './TimeSelect.scss';

export const TimeSelect: React.FC<TimeSelectProps> = (props) => {
    const {
        currentValue,
        onSelect,
        onTimeSelect,
        hours,
        minutes,
        seconds,
        hoursFormat = 12,
        hourStep,
        minuteStep,
        secondStep,
        dropdownPlacement,
        wrapperClassName,
        inputWrapperClassName,
        className,
        allowClear = false,
        hideOnSelect = false,
        disabled,
        units = 'ticks',
        value: _,
        onChange: __,
        ...rest
    } = props;

    const { setDropdownShown, dropdownShown, dropDownToggle, dropdownOptions } = useDropdown();

    const [time, setTime] = useState<ITime | null>(
        currentValue !== null ? transformTicksToTime(currentValue, hoursFormat, units) : null,
    );

    const [inputValue, setInputValue] = useState<string>('');

    const onWrapperClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
        if (!dropdownShown && !disabled) dropDownToggle();
    }, [dropDownToggle, dropdownShown, disabled]);

    const clearShowCondition = useMemo(() => {
        return !disabled && allowClear && currentValue !== null && currentValue !== undefined;
    }, [currentValue, allowClear, disabled]);

    const onClear = useCallback<MouseEventHandler>(
        (event) => {
            event?.stopPropagation();
            if (clearShowCondition) onTimeSelect(null);
        },
        [clearShowCondition, onTimeSelect],
    );

    useEffect(() => {
        if (currentValue !== undefined)
            setTime(currentValue !== null ? transformTicksToTime(currentValue, hoursFormat, units) : null);
    }, [currentValue, hoursFormat, units]);

    useEffect(() => {
        setInputValue(time ? formatTime(time, seconds) : '');
        if (hideOnSelect) setDropdownShown(false);
    }, [time, hoursFormat, hideOnSelect, setDropdownShown, seconds]);

    useEffect(() => {
        onTimeSelect(time ? transformTimeToTicks(time, units) : null);
    }, [time, onTimeSelect, units]);

    return (
        <div className={classNames('ui-time-select', wrapperClassName)}>
            <Input
                {...rest}
                value={inputValue}
                wrapperClassName={inputWrapperClassName}
                onWrapperClick={onWrapperClick}
                suffixIcon={clearShowCondition ? <ClearButton /> : <AiOutlineClockCircle />}
                onSuffixIconClick={onClear}
                disabled={disabled}
                className={classNames('ui-time-select__input', 'input-not-caret', className)}
            />
            <Dropdown
                dropdownPlacement={dropdownPlacement}
                dropdownOptions={dropdownOptions}
            >
                <TimeSelectDropdown
                    currentValue={time || undefined}
                    onTimeSelect={setTime}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    hoursFormat={hoursFormat}
                    hourStep={hourStep}
                    minuteStep={minuteStep}
                    secondStep={secondStep}
                />
            </Dropdown>
        </div>
    );
};
