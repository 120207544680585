import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './interfaces';
import { Loader } from '../Loader';
import './Button.scss';

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    const { className, loading, size = 'medium', children, disabled, kind = 'primary', onClick, ...rest } = props;

    const button = useRef<HTMLButtonElement>(null);

    const onButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            onClick?.(event);
            button?.current?.blur();
        },
        [onClick, button],
    );

    const [loaderShown, setLoaderShown] = useState<boolean>(false);

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoaderShown(true);
            }, 150);
        } else {
            setLoaderShown(false);
        }
    }, [loading]);

    return (
        <button
            ref={button}
            className={classNames('ui-button', `ui-button_${size}`, { loading }, className, kind)}
            {...rest}
            onClick={onButtonClick}
            disabled={disabled || loading}
        >
            <div className={classNames('ui-button__loader-wrapper', { shown: loading }, size)}>
                {loaderShown && (
                    <Loader
                        scale={size === 'small' ? 0.4 : 0.5}
                        className={classNames('ui-button__loader', size)}
                    />
                )}
            </div>
            {children}
        </button>
    );
};
