import { Api } from '@shared/api/Api';

export class SecurityTypes {
    private static _basePath = '/Collections';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${SecurityTypes._basePath}`;
        return {
            async get() {
                const path = '/security-types';
                const data = await Api.get<string[]>({
                    path: `${basePath}${path}`,
                });
                return data;
            },
        };
    }
}
