import { createContext } from 'react';
import { emptyAsyncFunc } from '@shared/react';
import { BooleanPermissions, BooleanFeatures, IPermissions } from './interfaces';
import { Authorization_AccessTokenPayload, QuantumUser } from '@shared/api';

export interface IOpenIdContext {
    logout: (callbackPath?: string | null | undefined, extras?: any) => Promise<void>;
    user: QuantumUser | null;
    features: BooleanFeatures | null;
    permissions: IPermissions | null;
    token: Authorization_AccessTokenPayload | null;
}

export const OpenIdContext = createContext<IOpenIdContext>({
    logout: emptyAsyncFunc,
    user: null,
    features: null,
    permissions: null,
    token: null,
});
