import { createContext } from 'react';
import { PushModal, SetCloseClassName } from './interfaces';

export interface IModalContext {
    pushModal: PushModal;
    removeModal: () => void;
    setCloseClassName: SetCloseClassName;
}

export const ModalContext = createContext<IModalContext>({
    pushModal: () => undefined,
    removeModal: () => undefined,
    setCloseClassName: () => undefined,
});
