import React from 'react';
import { InfoIcon } from '../InfoIcon';
import { InfoLabelProps } from './interfaces';
import classNames from 'classnames';
import './InfoLabel.scss';
import { formatCamelCaseString } from './lib/formatCamelCaseString';

export const InfoLabel: React.FC<InfoLabelProps> = (props) => {
    const { label, tooltip, className, onClick } = props;

    return (
        <label
            className={classNames('ui-info-label', className)}
            onClick={onClick}
        >
            <span>{formatCamelCaseString(label)}</span>
            {tooltip && <InfoIcon tooltip={tooltip} />}
        </label>
    );
};
