import { Api } from '@shared/api/Api';
import { WatchListsGetQuery, WatchListInstrumentsGetQuery } from './query';
import { IWatchListDto, IWatchListFullDto, IWatchListInstrumentsList } from './interfaces';
import {
    WatchListDeleteByIdParams,
    WatchListGetByIdParams,
    WatchListInstrumentsGetByWatchListIdParams,
    WatchListInstrumentPatchByWatchListIdParams,
    WatchListInstrumentDeleteByWatchListIdParams,
    WatchListPutByIdParams,
} from './params';
import { WatchListPostBody, WatchListPutBody, WatchListIntrumentPatchBody } from './body';
import { UniqueId } from '@shared/api/interfaces';

export class WatchLists {
    private static _basePath = '/WatchLists';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${WatchLists._basePath}`;
        return {
            async get(query?: WatchListInstrumentsGetQuery) {
                const path = '/GetAll';
                return await Api.get<IWatchListDto[]>({
                    path: `${basePath}${path}`,
                    query,
                });
            },
            async getById({ id }: WatchListGetByIdParams) {
                const path = '/';
                return await Api.get<IWatchListFullDto>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async post(body: WatchListPostBody) {
                const path = '/Create';
                return await Api.post<UniqueId>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async putById({ id }: WatchListPutByIdParams, body: WatchListPutBody) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async deleteById({ id }: WatchListDeleteByIdParams) {
                const path = '/Delete/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
            async getInstrumentsByWatchListId(
                { watchListId }: WatchListInstrumentsGetByWatchListIdParams,
                query: WatchListInstrumentsGetQuery,
            ) {
                const path = `/${watchListId}/Instruments`;
                return await Api.get<IWatchListInstrumentsList>({
                    path: `${basePath}${path}`,
                    query,
                });
            },
            async patchInstrumentByWatchListId(
                { watchListId }: WatchListInstrumentPatchByWatchListIdParams,
                body: WatchListIntrumentPatchBody,
            ) {
                const path = `/${watchListId}/Instruments/Add`;
                return await Api.patch<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async deleteInstrumentByWatchListId({ watchListId, id }: WatchListInstrumentDeleteByWatchListIdParams) {
                const path = `/${watchListId}/Instruments/Delete/`;
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
