import { ITime } from '../../TimeSelectDropdown';
import { TimeSelectorHoursFormat } from '../../TimeSelector';
import { Unit } from '../interfaces';

export function transformTicksToTime(ticks: number, hoursFormat: TimeSelectorHoursFormat, units: Unit): ITime {
    const trueTicks = units === 'ticks' ? ticks : ticks * 1_000;

    const clearTicks = trueTicks % (1_000 * 1_000 * 60 * 60 * 24);

    const milliseconds = Math.floor(clearTicks / 1_000);
    const tick = clearTicks - milliseconds * 1_000;
    const seconds = Math.floor(milliseconds / 1_000);
    const millisecond = milliseconds - seconds * 1_000;
    const minutes = Math.floor(seconds / 60);
    const second = seconds - minutes * 60 + Math.round((millisecond + Math.round(tick / 1_000)) / 1_000);
    const hour = Math.floor(minutes / 60);
    const minute = minutes - hour * 60;

    return {
        hour: hoursFormat === 12 ? hour % 12 : hour,
        minute,
        second,
        timeOfDay: hoursFormat === 12 ? (hour >= 12 ? 'PM' : 'AM') : undefined,
    };
}

export function transformTimeToTicks({ hour, minute, second, timeOfDay }: ITime, units: Unit): number {
    const additionalHours = timeOfDay === 'PM' ? 12 : 0;
    const additionalOrder = units === 'ticks' ? 1_000 : 1;
    return (((hour + additionalHours) * 60 + minute) * 60 + second) * 1_000 * additionalOrder;
}
