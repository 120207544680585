import React, { useMemo } from 'react';
import { ProgressBarProps } from './interfaces';
import './ProgressBar.scss';

export const ProgressBar: React.FC<ProgressBarProps> = ({ values, maxValue, label, defaultClassname }) => {
    const normalizedValues = useMemo(() => {
        return values.map(({ value, color, className }) => ({
            width: (100 * value) / maxValue,
            color,
            className,
        }));
    }, [values, maxValue]);

    return (
        <div className='ui-progress-bar'>
            <div className={`ui-progress-bar__bar ${defaultClassname}`}>
                {normalizedValues.map(({ width, color, className }, index) => (
                    <div
                        className={className}
                        key={index}
                        style={{
                            width: `${width}%`,
                            backgroundColor: color,
                        }}
                    />
                ))}
            </div>
            {label && (
                <span className='ui-progress-bar__label'>
                    {values.reduce((acc, { value }) => acc + value, 0)} / {maxValue}
                    {label ? ` ${label}` : ''}
                </span>
            )}
        </div>
    );
};
