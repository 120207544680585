import React from 'react';
import { Card, Preloader } from '@shared/UI';
import './SuspenseLoader.scss';

export const SuspenseLoader: React.FC = () => {
    return (
        <Preloader loading>
            <Card className='feature-suspense-loader' />
        </Preloader>
    );
};
