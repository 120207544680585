import { Api } from '../../Api';
import { IUserGroupsList, IUserGroupCreate, IUserGroupsDTO } from './interfaces';
import { QuantumUserGroupsPutBody } from './body';
import {
    QuantumUserGroupsGetByIdParams,
    QuantumUserGroupsPutByIdParams,
    QuantumUserGroupsDeleteByIdParams,
} from './params';
import { QuantumUserGroupsGetQuery } from './query';
export class UserGroups {
    private static _basePath = '/UserGroup';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${UserGroups._basePath}`;
        return {
            async get(query?: QuantumUserGroupsGetQuery) {
                const path = '/GetAll';
                return await Api.get<IUserGroupsList>({
                    path: `${basePath}${path}`,
                    query,
                });
            },
            async post(body: IUserGroupCreate) {
                const path = '/Create';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async getById({ id }: QuantumUserGroupsGetByIdParams, isMock: boolean = false) {
                const path = '/GetById/';
                return await Api.get<IUserGroupsDTO>({
                    path: `${basePath}${path}${id}`,
                    isMock,
                    mockName: 'Get User Group By Id',
                });
            },
            async putById(
                { id }: QuantumUserGroupsPutByIdParams,
                body: QuantumUserGroupsPutBody,
                isMock: boolean = false,
            ) {
                const path = '/Update/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Put User Group By Id',
                });
            },
            async deleteById({ id }: QuantumUserGroupsDeleteByIdParams) {
                const path = '/Delete/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
