import React from 'react';
import { SimpleTableProps } from './interfaces';
import { useSimpleTable } from './lib/useSimpleTable';
import { TableRow } from './components';
import classNames from 'classnames';
import './SimpleTable.scss';

export const SimpleTable: React.FC<SimpleTableProps> = (props) => {
    const { className, noDataLabel = 'Data is Empty', hideNoDataLabel, ...rest } = props;

    const { renderedHeader, renderedRows } = useSimpleTable(rest);

    return (
        <table className={classNames('ui-simple-table', className)}>
            <tbody className='ui-simple-table__body'>
                <TableRow
                    dataSet={renderedHeader}
                    isHeader
                />
                {!renderedRows.length && !hideNoDataLabel && (
                    <tr className='ui-simple-table__no-data'>
                        <td>{noDataLabel}</td>
                    </tr>
                )}
                {renderedRows.map(({ row, rowData }, index) => {
                    return (
                        <TableRow
                            key={index}
                            dataSet={row}
                            rowData={rowData}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};
