import React, { useMemo } from 'react';
import { RangeProps } from './interfaces';
import { InfoLabel } from '../InfoLabel';
import classNames from 'classnames';
import { colorBase } from './colorBase';
import './Range.scss';

export const Range: React.FC<RangeProps> = (props) => {
    const {
        disabled,
        prefixIcon,
        suffixIcon,
        onPrefixIconClick,
        onSuffixIconClick,
        className,
        wrapperClassName,
        label,
        infoTooltip,
        value,
        type: _,
        step = 1,
        max = 100,
        min = 1,
        units,
        badgeClassName,
        ...rest
    } = props;

    const localStep = useMemo(() => step || (+max - +min) / 200, [max, min, step]);

    const normalizedValue = useMemo(() => {
        if (value !== undefined) return ((+value - +min) / (+max - +min)) * 100;
        return 0;
    }, [value, min, max]);

    return (
        <div className='ui-range-wrapper'>
            {label && (
                <InfoLabel
                    label={label}
                    tooltip={infoTooltip}
                    className='ui-range-wrapper__label'
                />
            )}
            <div
                className={classNames('ui-range', wrapperClassName, {
                    disabled,
                })}
            >
                {prefixIcon && (
                    <aside
                        className={classNames('ui-range__icon prefix', {
                            interactive: !!onPrefixIconClick,
                        })}
                        onClick={onPrefixIconClick}
                    >
                        {prefixIcon}
                    </aside>
                )}
                <div className='ui-range__fill'></div>
                <input
                    type='range'
                    min={min}
                    max={max}
                    step={localStep}
                    className={classNames('ui-range__input', className)}
                    style={{
                        background: `linear-gradient(to left, ${colorBase.gray['quantum-light']} ${
                            100 - normalizedValue
                        }%, ${colorBase.primary['quantum-light']} ${100 - normalizedValue}%)`,
                    }}
                    disabled={disabled}
                    value={value}
                    {...rest}
                />
                {suffixIcon && (
                    <aside
                        className={classNames('ui-range__icon suffix', {
                            interactive: !!onSuffixIconClick,
                        })}
                        onClick={onSuffixIconClick}
                    >
                        {suffixIcon}
                    </aside>
                )}
                <span className={classNames('ui-range__badge', badgeClassName)}>
                    {value}
                    {units}
                </span>
            </div>
        </div>
    );
};
