import { createContext, Dispatch, SetStateAction } from 'react';
import { IRouteGroup } from '../../api';
import { emptyFunc } from '@shared/react';

export interface IMainLayoutContextContext {
    routeGroups: IRouteGroup[] | null;
    sidebarFixed: boolean;
    sideBarToggleClick: () => void;
    currentPath: string | null;
    setCurrentPath: Dispatch<SetStateAction<string | null>>;
}

export const MainLayoutContext = createContext<IMainLayoutContextContext>({
    routeGroups: [],
    sidebarFixed: false,
    sideBarToggleClick: emptyFunc,
    currentPath: null,
    setCurrentPath: emptyFunc,
});
