import React from 'react';
import { Routes as NativeRoutes, Route } from 'react-router-dom';
import { useRouter } from './lib';
import { BlockNotFound } from '@widgets/BlockNotFound';

export const Routes: React.FC = () => {
    const { router } = useRouter();

    return (
        <NativeRoutes>
            {router.map((routerItem) => {
                const RenderedComponent = routerItem.Component;
                return (
                    <Route
                        key={routerItem.path}
                        path={routerItem.path}
                        element={<RenderedComponent />}
                    />
                );
            })}
            <Route
                path='*'
                element={<BlockNotFound />}
            />
        </NativeRoutes>
    );
};
