import React from 'react';
import { SomethingWentWrongPage } from '@pages/SomethingWentWrongPage';

type ErrorBoundaryProps = React.PropsWithChildren;

type ErrorBoundaryState = {
    error: string | null;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: string) {
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log({ error, errorInfo });
    }

    render() {
        if (this.state.error) return <SomethingWentWrongPage />;
        return this.props.children;
    }
}
