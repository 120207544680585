import { Suspense } from 'react';
import { SuspenseLoader } from './components';
import { ReactHOC } from '@shared/react';

export const withSuspense: ReactHOC = (Component) => {
    return (props) => {
        return (
            <Suspense fallback={<SuspenseLoader />}>
                <Component {...props} />
            </Suspense>
        );
    };
};
