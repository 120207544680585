import { useMemo } from 'react';
import { useMainLayout } from '@widgets/MainLayout';
import { IRouterItem, mapRouteGroupsToRouter } from './lib';

interface Returned {
    router: IRouterItem[];
}

export function useRouter(): Returned {
    const { routeGroups } = useMainLayout();

    return {
        router: useMemo(() => mapRouteGroupsToRouter(routeGroups), [routeGroups]),
    };
}
