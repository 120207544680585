import { Field } from '../interfaces';
import { getValue } from './lib';

function getKeyValuePair<Dto extends Record<keyof Dto, any> = any>(field: Field): [keyof Dto, any] {
    const { fieldName, value } = field;
    return [fieldName as keyof Dto, getValue(value)];
}

export function generateMock<Dto extends Record<keyof Dto, any> = any>(fields: Field[]): Dto {
    const obj: Partial<Record<keyof Dto, Dto[keyof Dto]>> = {};

    fields.forEach((field) => {
        const [key, value] = getKeyValuePair<Dto>(field);
        obj[key] = value;
    });

    return obj as Dto;
}
