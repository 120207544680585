import React, { useId } from 'react';
import { InfoIconProps } from './interfaces';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from '../Tooltip';
import classNames from 'classnames';
import './InfoIcon.scss';

export const InfoIcon: React.FC<InfoIconProps> = (props) => {
    const { tooltip, className, placement = 'top', icon: Icon } = props;

    const id = useId();

    return (
        <span
            data-tooltip-id={id}
            className={classNames('ui-info-icon', className)}
        >
            {Icon ? <Icon /> : <AiOutlineInfoCircle id={id} />}
            <Tooltip
                id={id}
                place={placement}
                content={tooltip}
            />
        </span>
    );
};
