import { QuantumMenuReactCodes } from './enums';

export const QuantumMenuMockRouteGroups = [
    {
        name: 'Market Watch',
        path: '/market-watch',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'Market Watch',
                path: '/market-watch',
                reactCode: QuantumMenuReactCodes.MarketWatch,
            },
        ],
    },
    {
        name: 'Market Depth',
        path: '/market-depth',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'Market Depth',
                path: 'market-depth',
                reactCode: QuantumMenuReactCodes.MarketDepth,
            },
        ],
    },
    {
        name: 'QTS Trade Entry',
        path: '/trade-entry',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'QTS Trade Entry',
                path: '/trade-entry',
                reactCode: QuantumMenuReactCodes.QTSTradeEntry,
            },
        ],
    },
    {
        name: 'QTS Trade Status',
        path: '/trade-status',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'QTS Trade Status',
                path: '/trade-status',
                reactCode: QuantumMenuReactCodes.QTSTradeStatus,
            },
        ],
    },
    {
        name: 'QTS Trade Templates',
        path: '/trade-templates',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'QTS Trade Templates',
                path: '/trade-templates',
                reactCode: QuantumMenuReactCodes.QTSTradeTemplates,
            },
        ],
    },
    {
        name: 'QTS Post Trade',
        path: '/post-trade',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'QTS Post Trade',
                path: '/post-trade',
                reactCode: QuantumMenuReactCodes.QTSPostTrade,
            },
        ],
    },
    {
        name: 'Admin',
        path: '/admin',
        routeGroups: null,
        routes: null,
        tabs: [
            {
                name: 'Quantum Admins',
                path: '/admin',
                reactCode: QuantumMenuReactCodes.Admin,
            },
            {
                name: 'Brokers',
                path: '/admin/brokers',
                reactCode: QuantumMenuReactCodes.Broker,
            },
            {
                name: 'Broker Admins',
                path: '/admin/broker-admins',
                reactCode: QuantumMenuReactCodes.BrokerAdmin,
            },
            {
                name: 'User Groups',
                path: '/admin/user-groups',
                reactCode: QuantumMenuReactCodes.UserGroups,
            },
            {
                name: 'User Admins',
                path: '/admin/user-admins',
                reactCode: QuantumMenuReactCodes.UserAdmins,
            },
            {
                name: 'Users',
                path: '/admin/users',
                reactCode: QuantumMenuReactCodes.Users,
            },
        ],
    },
];

export const BrokerDealerAdminMenuMockRouteGroups = QuantumMenuMockRouteGroups.map((item) => {
    if (item.name === 'Admin') {
        return {
            ...item,
            tabs: [
                {
                    name: 'User Groups',
                    path: '/admin/user-groups',
                    reactCode: QuantumMenuReactCodes.UserGroups,
                },
                {
                    name: 'User Admins',
                    path: '/admin/user-admins',
                    reactCode: QuantumMenuReactCodes.UserAdmins,
                },
                {
                    name: 'Users',
                    path: '/admin/users',
                    reactCode: QuantumMenuReactCodes.Users,
                },
            ],
        };
    }
    return item;
});

export const BrokerAdminMenuMockRouteGroups = BrokerDealerAdminMenuMockRouteGroups.map((item) => {
    if (item.name === 'Admin') {
        return {
            ...item,
            tabs: [
                {
                    name: 'Users',
                    path: '/admin/users',
                    reactCode: QuantumMenuReactCodes.Users,
                },
            ],
        };
    }
    return item;
});

export const EndUserMenuMockRouteGroups = BrokerAdminMenuMockRouteGroups.filter((item) => item.name !== 'Admin');
