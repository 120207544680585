import { Nullable } from '@shared/api/interfaces';
import pako from 'pako';

const base64UrlDecode = (str: string) => {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (str.length % 4) {
        case 0:
            break;
        case 2:
            str += '==';
            break;
        case 3:
            str += '=';
            break;
        default:
            throw 'Illegal base64url string!';
    }
    return atob(str);
};

export const decodeToken = (token: string) => {
    const [, payload] = token.split('.');
    const decodedPayload = JSON.parse(base64UrlDecode(payload));
    return decodedPayload;
};

export const decodePermissions = (compressedBase64: Nullable<string>) => {
    if (!compressedBase64) return null;
    const binaryString = atob(compressedBase64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decompressed = pako.ungzip(bytes, { to: 'string' });

    return JSON.parse(decompressed);
};
