import React from 'react';
import { BlockSomethingWentWrong } from '@widgets/BlockSomethingWentWrong';
import './SomethingWentWrongPage.scss';

export const SomethingWentWrongPage: React.FC = () => {
    return (
        <div className='page-something-went-wrong'>
            <BlockSomethingWentWrong />
        </div>
    );
};
