import { General } from '../General';
import { AuthorizationV1 } from './v1/v1';
import { AuthorizationV2 } from './v2/v2';

export class AuthorizationAPI {
    private static _basePath = General.getAuthorizationUrl();

    static getMethods() {
        return {
            v1: AuthorizationV1.getMethods(AuthorizationAPI._basePath),
            v2: AuthorizationV2.getMethods(AuthorizationAPI._basePath),
        };
    }
}
