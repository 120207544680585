import moment from 'moment';
import { QuantumTradeStatusesTemplateDto, QuantumTradeStatusesTemplateFullDto } from '../interfaces';
import { generateId } from '../../../../utils';
import { Field, generateRandomInteger, generateRandomISOString, generateRandomLastName } from '../../../lib';

const formatTemplatesDate = (): string => {
    return moment(generateRandomISOString()).format('hh:mm:ss');
};

const generateTemplatesId = () => generateId().slice(0, 8);

export const mockTradeStatusFields: Field<QuantumTradeStatusesTemplateDto>[] = [
    {
        fieldName: 'id',
        value: generateTemplatesId,
    },
    {
        fieldName: 'shortName',
        value: generateRandomLastName,
    },
    {
        fieldName: 'timePlaced',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'duration',
        value: '01:18:20',
    },
    {
        fieldName: 'status',
        value: 'ACTIVE',
    },
    {
        fieldName: 'percentOfValueExecuted',
        value: () => generateRandomInteger(2),
    },
    {
        fieldName: 'valueExecuted',
        value: () => generateRandomInteger(5),
    },
    {
        fieldName: 'valueRemaining',
        value: () => generateRandomInteger(5),
    },
    {
        fieldName: 'userId',
        value: generateId,
    },
    {
        fieldName: 'lastUpdate',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'scriptId',
        value: generateTemplatesId,
    },
];

export const MockTradeStatusFullFields: Field<QuantumTradeStatusesTemplateFullDto>[] = [
    {
        fieldName: 'id',
        value: generateTemplatesId,
    },
    {
        fieldName: 'shortName',
        value: generateRandomLastName,
    },
    {
        fieldName: 'timePlaced',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'duration',
        value: '01:18:20',
    },
    {
        fieldName: 'status',
        value: 'ACTIVE',
    },
    {
        fieldName: 'percentOfValueExecuted',
        value: () => generateRandomInteger(2),
    },
    {
        fieldName: 'valueExecuted',
        value: () => generateRandomInteger(5),
    },
    {
        fieldName: 'valueRemaining',
        value: () => generateRandomInteger(5),
    },
    {
        fieldName: 'userId',
        value: generateId,
    },
    {
        fieldName: 'lastUpdate',
        value: formatTemplatesDate,
    },
    {
        fieldName: 'scriptId',
        value: generateTemplatesId,
    },
];
