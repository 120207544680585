export enum EAuthorization_Permissions {
    ManagePermissionGroups = 'ManagePermissionGroups',

    ViewAdminCompanyInformation = 'ViewAdminCompanyInformation',
    ViewAdminGeneralSettings = 'ViewAdminGeneralSettings',
    ViewAdminQuantumSettings = 'ViewAdminQuantumSettings',
    ViewAdminAgentPortalSettings = 'ViewAdminAgentPortalSettings',
    ViewEmployees = 'ViewEmployees',
    ViewPermissionGroups = 'ViewPermissionGroups',
    ViewCampaigns = 'ViewCampaigns',
    ViewSkills = 'ViewSkills',
    ViewSkillToAgentRelationships = 'ViewSkillToAgentRelationships',
    ViewSkillGroups = 'ViewSkillGroups',

    ViewQuantumCampaignGroups = 'ViewQuantumCampaignGroups',
    ViewQuantumWeeklyPatterns = 'ViewQuantumWeeklyPatterns',
    ViewQuantumMonthlyPatterns = 'ViewQuantumMonthlyPatterns',

    ViewQuantumDayPlanner = 'ViewQuantumDayPlanner',

    ViewSchedulingEvents = 'ViewSchedulingEvents',
    ViewSchedulingEventType = 'ViewSchedulingEventType',
    ViewSchedulingShiftType = 'ViewSchedulingShiftType',
    ViewSchedulingShiftTemplate = 'ViewSchedulingShiftTemplate',
    ViewSchedulingScheduleCalculation = 'ViewSchedulingScheduleCalculation',
    ViewSchedulingScheduleAssignment = 'ViewSchedulingScheduleAssignment',
    ViewSchedulingScheduleAutoAssignment = 'ViewSchedulingScheduleAutoAssignment',
    ViewSingleAdHocEvent = 'ViewSingleAdHocEvent',

    ViewReportingScheduleAdherenceReport = 'ViewReportingScheduleAdherenceReport',
    ViewReportingDailyIdpReport = 'ViewReportingDailyIdpReport',
    ViewReportingProductivityReport = 'ViewReportingProductivityReport',

    ViewSupervisorPortal = 'ViewSupervisorPortal',
    ViewRtaPortal = 'ViewRtaPortal',
    ViewAgentPortal = 'ViewAgentPortal',

    SkillGroupManagement = 'SkillGroupManagement',
}

export enum EAuthorization_Features {
    'SuperAdmin' = 'SuperAdmin',
    'Ad Hoc Events Scheduling Feature' = 'Ad Hoc Events Scheduling Feature',
    'Multi-skill Quantum Feature' = 'Multi-skill Quantum Feature',
    'Supervisor Portal Feature' = 'Supervisor Portal Feature',
    'Auto-assignment Scheduling Feature' = 'Auto-assignment Scheduling Feature',
    'Compiled Quantum Feature' = 'Compiled Quantum Feature',
    'Realtime Portal Feature' = 'Realtime Portal Feature',
    'Agent Portal Feature' = 'Agent Portal Feature',
    'Production Quantum Feature' = 'Production Quantum Feature',
    'Reporting Feature' = 'Reporting Feature',
    'Agents_2051f03f-d46d-430d-af1d-7870bee48dc3' = 'Agents_2051f03f-d46d-430d-af1d-7870bee48dc3',
    'Custom_2051f03f-d46d-430d-af1d-7870bee48dc3' = 'Custom_2051f03f-d46d-430d-af1d-7870bee48dc3',
    'Administrators_2051f03f-d46d-430d-af1d-7870bee48dc3' = 'Administrators_2051f03f-d46d-430d-af1d-7870bee48dc3',
}

export enum EAuthorization_Boolean {
    True = 'True',
    False = 'False',
}

export enum EAuthorization_ContactCenterType {
    QUANTUM = 'QUANTUM',
}

export enum EAuthorization_QuantumClientCode {
    quantumreporting = 'quantumreporting',
    QuantumDEMO = 'QuantumDEMO',
}

export enum EAuthorization_AdapterMode {
    Record = 'Record',
    Script = 'Script',
}
