import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@shared/UI';
import './InfoComponent.scss';

type AuthInfoType = 'loading' | 'authenticating' | 'success' | 'error' | 'session-lost' | 'unsupported';

interface InfoComponentProps {
    type?: AuthInfoType;
}

export const InfoComponent: React.FC<InfoComponentProps> = (props) => {
    const { type } = props;

    const navigate = useNavigate();

    useEffect(() => {
        if (type === 'session-lost') navigate(0);
    }, [type]);

    const header = useMemo(() => {
        if (type === 'loading') {
            return 'Loading...';
        }
        if (type === 'authenticating') {
            return 'Authentication in progress';
        }
        if (type === 'success') {
            return 'Authentication complete';
        }
        if (type === 'error') {
            return 'Error authentication';
        }
        if (type === 'session-lost') {
            return 'Session timed out';
        }
        if (type === 'unsupported') {
            return 'Unable to authenticate on this browser';
        }
        return 'Oops... Something went wrong!';
    }, [type]);

    const info = useMemo(() => {
        if (type === 'loading') {
            return 'Please wait while authentication loads data';
        }
        if (type === 'authenticating') {
            return 'You will be redirected to the login page';
        }
        if (type === 'success') {
            return 'You will be redirected to your application';
        }
        if (type === 'error') {
            return 'An error occurred during authentication';
        }
        if (type === 'session-lost') {
            return 'Your session has expired. Please re-authenticate';
        }
        if (type === 'unsupported') {
            return 'Your browser is not secure enough to make authentication work. Try updating your browser or use a newer browser';
        }
        return 'Something went wrong!';
    }, [type]);

    const isLoader = useMemo(() => {
        return type === 'authenticating' || type === 'loading' || type === 'success';
    }, [type]);

    return (
        <section className='entity-info-component'>
            <h2 className='entity-info-component__header'>{header}</h2>
            <p className='entity-info-component__info'>{info}</p>
            {isLoader && <Loader />}
            {type === 'error' && (
                <a
                    className='entity-info-component__link'
                    href='/src/pages'
                >
                    Go to main or try again
                </a>
            )}
        </section>
    );
};
