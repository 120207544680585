import { OrderStatus, QTSTemplate } from '@widgets/TradeEntry/api';
import { NotificationType, RequestType } from './interfaces-protocol';

export namespace TradeEntryMessages {
    export const GetSecurities = new RequestType<[string], string[]>('security/list');
    export const GetPrice = new RequestType<[string], string>('security/price');
    export const SaveTemplate = new RequestType<[QTSTemplate], number>('template/post');
    export const UpdateTemplate = new RequestType<[number, QTSTemplate], number>('template/put');
    export const GetTemplates = new RequestType<[string], QTSTemplate[]>('template/list');
    export const LoadTemplate = new RequestType<[number], QTSTemplate>('template/get');
    export const ValidateTemplate = new RequestType<[QTSTemplate], string>('template/validate');
    export const DeleteTemplate = new RequestType<[string], boolean>('template/delete');

    /** Client can request recent order execution status updates. TODO: Param type to be defined allowing limit the no of statuses to be transferred. */
    export const GetExecutionStatuses = new RequestType<[number, number?], OrderStatus[]>('trade/listStatuses');

    export type OrderSubmissionResult = {
        orderId: number;
        status: string;
        details: string;
    };

    export const SimulateOrder = new RequestType<[QTSTemplate], OrderSubmissionResult>('trade/simulate');
    export const ExecuteOrder = new RequestType<[QTSTemplate], OrderSubmissionResult>('trade/execute');

    export const OrderUpdates = new NotificationType<OrderStatus[]>('order/updates');
}
