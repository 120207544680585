import React, { useCallback, useId, useMemo, useState } from 'react';
import { Avatar, Button, Tooltip } from '@shared/UI';
import { BiRun } from 'react-icons/bi';
import { useElement, useElementDimensions } from '@shared/react';
import {
    USER_AVATAR_BUTTON_WIDTH,
    USER_AVATAR_LEFT_BUTTON_MARGIN,
    USER_AVATAR_LEFT_PADDING,
    USER_AVATAR_RIGHT_BUTTON_MARGIN,
} from './lib';
import { useOpenId } from '@entities/OpenIDProvider';
import './UserAvatar.scss';

export const UserAvatar: React.FC = () => {
    const { logout: openId0Logout, user, token } = useOpenId();

    const [blockWidth, setBlockWidth] = useState<number>(46);

    const { target: emailSpan } = useElement<HTMLSpanElement>();
    const { width, refreshValues } = useElementDimensions({ target: emailSpan });

    const onBlockOver = useCallback<React.MouseEventHandler>(() => {
        setBlockWidth(
            width +
                USER_AVATAR_LEFT_BUTTON_MARGIN +
                USER_AVATAR_RIGHT_BUTTON_MARGIN +
                USER_AVATAR_BUTTON_WIDTH +
                USER_AVATAR_LEFT_PADDING,
        );
    }, [width]);

    const onBlockLeave = useCallback<React.MouseEventHandler>(() => {
        setBlockWidth(46);
    }, []);

    const logoutClick = useCallback(async () => {
        await openId0Logout(window.location.origin);
    }, [openId0Logout]);

    const userName = useMemo(() => {
        return `${token?.given_name} ${token?.family_name}`;
    }, [user, refreshValues]);

    const id = useId();

    return (
        <div
            className='widget-user-avatar'
            onMouseOver={onBlockOver}
            onMouseLeave={onBlockLeave}
            style={{ width: blockWidth }}
        >
            <span
                ref={emailSpan}
                className='widget-user-avatar__email'
            >
                {userName}
            </span>
            <Button
                data-tooltip-id={id}
                id='logout-button'
                size='small'
                className='widget-user-avatar__logout-button'
                onClick={logoutClick}
            >
                <BiRun />
            </Button>
            <Tooltip
                id={id}
                place='right'
                content='Logout'
            />
            <Avatar
                src={user?.picture}
                firstName={user?.given_name}
                lastName={user?.family_name}
                className='widget-user-avatar__avatar'
            />
        </div>
    );
};
