import { Api } from '@shared/api/Api';
import { UniqueId } from '@shared/api/interfaces';
import { IAdminCreate, IAdminDto, IAdminUpdate } from './interfaces';
import { QuantumAdminsGetQuery } from './interfaces';

export class Admins {
    private static _basePath = '/Admin';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${Admins._basePath}`;
        return {
            async get(query: QuantumAdminsGetQuery) {
                const path = '';
                const data = await Api.get<IAdminDto>({
                    path: `${basePath}${path}`,
                    query,
                });
                return data;
            },
            async post(body: IAdminCreate) {
                const path = '';
                return await Api.post<IAdminCreate>({
                    path: `${basePath}${path}`,
                    body,
                });
            },
            async putById({ id }: { id: UniqueId }, body: IAdminUpdate) {
                const path = '/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                });
            },
            async deleteById({ id }: { id: UniqueId }) {
                const path = `/`;
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                });
            },
        };
    }
}
