import React from 'react';
import { useSidebar } from './lib';
import { SidePanelExpandToggle } from '@shared/UI';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
import { SideMenu } from './components';
import { UserAvatar } from '@widgets/UserAvatar';

export const Sidebar: React.FC = () => {
    const { sidebarFixed, sidebarShown, linksShown, sideBarToggleClick, sidebarMouseLeave, sidebarMouseOver } =
        useSidebar();

    return (
        <nav
            className={classNames('widget-main-layout-sidebar', {
                shown: sidebarShown,
            })}
            onMouseOver={sidebarMouseOver}
            onMouseLeave={sidebarMouseLeave}
        >
            <Link
                to='/'
                className={classNames('widget-main-layout-sidebar__logo', { sidebarHidden: !sidebarShown })}
            >
                <img
                    src='/qtc-logo.png'
                    alt='qts-logo'
                    className='widget-main-layout-sidebar__logo-image'
                />
            </Link>

            <div className={classNames('widget-main-layout-sidebar__routes', { linksShown })}>
                <SideMenu />
            </div>

            <SidePanelExpandToggle
                expanded={sidebarFixed}
                onClick={sideBarToggleClick}
                className={classNames('widget-main-layout-sidebar__toggle', {
                    sidebarShown,
                })}
            />
            <UserAvatar />
        </nav>
    );
};
