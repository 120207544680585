import { User_Roles } from '@shared/api';
import {
    BrokerAdminMenuMockRouteGroups,
    BrokerDealerAdminMenuMockRouteGroups,
    EndUserMenuMockRouteGroups,
    QuantumMenuMockRouteGroups,
} from '../constants';

export const getRouteGroup = (role?: keyof typeof User_Roles) => {
    switch (role) {
        case 'QuantumSuperAdmin':
            return QuantumMenuMockRouteGroups;
        case 'BrokerDealerAdmin':
            return BrokerDealerAdminMenuMockRouteGroups;
        case 'EndUserAdmin':
            return BrokerAdminMenuMockRouteGroups;
        case 'EndUser':
            return EndUserMenuMockRouteGroups;
        default:
            return [];
    }
};
