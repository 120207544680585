import { ListResult } from '../../../interfaces';
import { generateArrayMock } from '../generateArrayMock';
import { Field } from '../interfaces';

interface Options {
    totalCount: number;
    page: number;
    pageSize: number;
    fields: Field[];
}

export function generateMockListResult<Dto extends Record<keyof Dto, any> = any>(options: Options): ListResult<Dto> {
    const { totalCount, page, pageSize, fields } = options;

    const pagesCount = Math.ceil(totalCount / pageSize);
    const count = page < pagesCount - 1 ? pageSize : totalCount % pageSize;

    return {
        results: generateArrayMock({ fields, count }),
        totalCount,
    };
}
