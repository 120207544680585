import React, { useMemo } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { generateId } from '../../utils';
import { AvatarProps } from './interfaces';
import classNames from 'classnames';
import './Avatar.scss';

export const Avatar: React.FC<AvatarProps> = (props) => {
    const { src, className, firstName, lastName } = props;

    const content = useMemo<React.ReactNode>(() => {
        if (src) {
            return (
                <img
                    className='ui-avatar__image'
                    src={src + `?refresh-hash=${generateId()}`}
                    alt='agent-avatar'
                />
            );
        }
        if (!!firstName || !!lastName) {
            const label = (firstName?.slice(0, 1).toUpperCase() || '') + (lastName?.slice(0, 1).toUpperCase() || '');
            return <span className='ui-avatar__no-image abbreviation'>{label}</span>;
        }
        return (
            <span className='ui-avatar__no-image empty'>
                <BsFillPersonFill />
            </span>
        );
    }, [src, firstName, lastName]);

    return (
        <div
            className={classNames(
                'ui-avatar',
                className,
                { abbreviation: !src && (firstName || lastName) },
                { empty: !src && !firstName && !lastName },
            )}
        >
            {content}
        </div>
    );
};
