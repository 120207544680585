import React from 'react';
import { SidePanelExpandToggleProps } from './indetfaces';
import classNames from 'classnames';
import './SidePanelExpandToggle.scss';

export const SidePanelExpandToggle: React.FC<SidePanelExpandToggleProps> = (props) => {
    const { onClick, expanded, className } = props;

    return (
        <div
            className={classNames('ui-side-panel-expand-toggle', { expanded }, className)}
            onClick={onClick}
        >
            <div />
            <div />
            <div />
        </div>
    );
};
