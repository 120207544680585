import React from 'react';
import { LoaderProps } from './interfaces';
import classNames from 'classnames';
import './Loader.scss';

export const Loader: React.FC<LoaderProps> = (props) => {
    const { scale = 1, className } = props;

    return (
        <div
            className={classNames('ui-loader', className)}
            style={{
                transform: `scale(${scale})`,
            }}
        >
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};
