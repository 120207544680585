import React, { MouseEventHandler, useCallback, useState, Fragment, useEffect } from 'react';
import { SelectDropdownProps } from '../SelectDropdown';
import classNames from 'classnames';
import { SelectOption } from '../Select';
import { DropdownPlacement } from '../Dropdown';
import './MultiSelectDropdown.scss';
import { Checkbox } from '../Checkbox';
import { FaAngleRight } from 'react-icons/fa6';

interface MultiSelectDropdownProps extends SelectDropdownProps {
    dropdownPlacement?: DropdownPlacement;
    onSelect: (selectedOption: SelectOption) => void;
    loading?: boolean;
}

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = (props) => {
    const {
        options,
        currentValue,
        onSelect,
        optionTitle = false,
        loading = false,
        className,
        noDataLabel = 'Data is Empty',
    } = props;

    const [expandedOptions, setExpandedOptions] = useState<Set<string>>(new Set());
    const [selectedChildren, setSelectedChildren] = useState<SelectOption>();

    const toggleExpand = (value: string) => {
        setExpandedOptions((prev) => {
            if (prev.has(value)) {
                return new Set();
            }
            return new Set([value]);
        });
    };

    const optionClick = useCallback<(option: SelectOption) => MouseEventHandler>(
        (option) => () => {
            setSelectedChildren(option);
            onSelect(option);
            toggleExpand(option.value.toString());
        },
        [onSelect, currentValue],
    );

    const isOptionSelected = useCallback(
        (option: SelectOption) => {
            return Array.isArray(currentValue) && currentValue.includes(option.value);
        },
        [currentValue],
    );

    const selectAllChildren = (children: SelectOption[], parent: SelectOption) => {
        const gatherAllChildren = (options: SelectOption[]): SelectOption[] => {
            return options.flatMap((option) => {
                if (option.children) {
                    return [option, ...gatherAllChildren(option.children)];
                }
                return [option];
            });
        };

        const allOptions = [parent, ...gatherAllChildren(children)];

        allOptions.forEach((option) => {
            onSelect(option);
        });
    };

    const areAllChildrenSelected = (children: SelectOption[]): boolean => {
        return children.every((child) => {
            if (child.children) {
                return areAllChildrenSelected(child.children);
            }
            return isOptionSelected(child);
        });
    };

    // useEffect(() => {
    //     console.log(selectedChildren);
    // }, [expandedOptions]);

    const getExpanded = (option?: SelectOption) => {
        console.log(option?.value, expandedOptions, expandedOptions.has(option?.value?.toString()));
        expandedOptions.has(option?.value?.toString());
    };

    return (
        <div
            className={classNames(
                'multi-select-dropdown hello ui-select-dropdown',
                { noData: !options.length },
                className,
            )}
        >
            {!options.length && <div className='ui-select-dropdown__no-data'>{noDataLabel}</div>}
            {options.map((option) => {
                const isExpanded = expandedOptions.has(option?.value?.toString());
                const isSelected = isOptionSelected(option);
                const allChildrenSelected = option.children ? areAllChildrenSelected(option.children) : false;

                return (
                    <Fragment key={option.value}>
                        <div
                            className={classNames(
                                'ui-select-dropdown__option',
                                { selected: option.value === currentValue },
                                { flexEnd: !option.label },
                            )}
                            onClick={optionClick(option)}
                            title={optionTitle ? option.label : ''}
                        >
                            <div className='ui-select-dropdown__select_all'>
                                {option.children ? (
                                    <>
                                        {/* <Checkbox
                                            type='square'
                                            onChange={(e: PointerEvent) => {
                                                e.stopPropagation();
                                                option.children && selectAllChildren(option.children, option);
                                            }}
                                            checked={allChildrenSelected}
                                        /> */}
                                    </>
                                ) : (
                                    <Checkbox
                                        onChange={() => optionClick(option)}
                                        checked={isSelected}
                                    />
                                )}
                                <span>{option.label}</span>
                            </div>
                            {option.children && <FaAngleRight fill='white' />}
                        </div>
                        {option.children && (
                            <div
                                className={classNames('ui-select-dropdown-children__option multiLevel-menu', {
                                    shown: isExpanded,
                                })}
                            >
                                {!loading && (
                                    <MultiSelectDropdown
                                        options={option.children}
                                        currentValue={currentValue}
                                        onSelect={onSelect}
                                        optionTitle={true}
                                        className={classNames('nested-dropdown', { shown: isExpanded })}
                                    />
                                )}
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
