import { Api } from '../../Api';
import { ListResult } from '../../interfaces';
import { QuantumTradeTemplatesTemplateDto, QuantumTradeTemplatesTemplateFullDto } from './interfaces';
import { QuantumTradeTemplatesPostBody, QuantumTradeTemplatesPutBody } from './body';
import {
    QuantumTradeTemplatesGetByIdParams,
    QuantumTradeTemplatesPutByIdParams,
    QuantumTradeTemplatesDeleteByIdParams,
} from './params';
import { QuantumTradeTemplatesGetQuery } from './query';
import { getQuantumTradeTemplatesMockTemplate, getQuantumTradeTemplatesMockTemplates } from './mocks';

export class TradeTemplates {
    private static _basePath = '/trade-templates';

    public static getMethods(parentPath: string) {
        const basePath = `${parentPath}${TradeTemplates._basePath}`;
        return {
            async get(query: QuantumTradeTemplatesGetQuery, isMock: boolean = false) {
                const path = '/';
                return await Api.get<ListResult<QuantumTradeTemplatesTemplateDto>>({
                    path: `${basePath}${path}`,
                    query,
                    isMock,
                    mock: () =>
                        getQuantumTradeTemplatesMockTemplates({ page: query.page, pageSize: query.recordsCount }),
                    mockName: 'Get Trade Templates',
                });
            },
            async post(body: QuantumTradeTemplatesPostBody, isMock: boolean = false) {
                const path = '/';
                return await Api.post<void>({
                    path: `${basePath}${path}`,
                    body,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Post Trade Template',
                });
            },
            async getById({ id }: QuantumTradeTemplatesGetByIdParams, isMock: boolean = false) {
                const path = '/';
                return await Api.get<QuantumTradeTemplatesTemplateFullDto>({
                    path: `${basePath}${path}${id}`,
                    isMock,
                    mock: getQuantumTradeTemplatesMockTemplate,
                    mockName: 'Get Trade Template By Id',
                });
            },
            async putById(
                { id }: QuantumTradeTemplatesPutByIdParams,
                body: QuantumTradeTemplatesPutBody,
                isMock: boolean = false,
            ) {
                const path = '/';
                return await Api.put<void>({
                    path: `${basePath}${path}${id}`,
                    body,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Put Trade Template By Id',
                });
            },
            async deleteById({ id }: QuantumTradeTemplatesDeleteByIdParams, isMock: boolean = false) {
                const path = '/';
                return await Api.delete<void>({
                    path: `${basePath}${path}${id}`,
                    isMock,
                    mock: () => undefined,
                    mockName: 'Delete Trade Template By Id',
                });
            },
        };
    }
}
