import { IRouteGroup, IRoute, ITab } from '@widgets/MainLayout';
import { reactComponentsBase } from '../../../lib';
import { IRouterItem } from './interfaces';
import { withSuspense } from '@features/withSuspense';
import { BlockNotFound } from '@widgets/BlockNotFound';

function mapTabsToRouter(tabs: ITab[]): IRouterItem[] {
    return tabs.map((tab) => {
        return {
            path: tab.path,
            Component: withSuspense(reactComponentsBase[tab.reactCode] ?? BlockNotFound),
        };
    });
}

function mapRoutesToRouter(routes: IRoute[]): IRouterItem[] {
    return routes.reduce((router, route) => {
        return [...router, ...mapTabsToRouter(route.tabs)];
    }, [] as IRouterItem[]);
}

export function mapRouteGroupsToRouter(routeGroups: IRouteGroup[] | null): IRouterItem[] {
    const routes =
        routeGroups?.reduce((router, routeGroup) => {
            if (routeGroup.routeGroups) return [...router, ...mapRouteGroupsToRouter(routeGroup.routeGroups)];
            if (routeGroup.routes) return [...router, ...mapRoutesToRouter(routeGroup.routes)];
            if (routeGroup.tabs) return [...router, ...mapTabsToRouter(routeGroup.tabs)];
            return router;
        }, [] as IRouterItem[]) || [];

    const mainPage: IRouterItem = {
        path: '/',
        Component: withSuspense(reactComponentsBase.MainPage ?? BlockNotFound),
    };

    return [mainPage, ...routes];
}
