export const getApiErrorWithEntityName = (ex?: any, entityName?: string, defaultMessage = 'Something went wrong!') => {
    const messages = ex?.response?.data?.messages;
    const mainErrorMessage = ex?.response?.data?.mainErrorMessage;
    if (messages && Object.keys(messages).length > 0) {
        const key = Object.keys(messages)[0];
        return messages[key];
    } else if (mainErrorMessage) {
        return mainErrorMessage;
    }
    if (entityName) return `Something went wrong while fetching ${entityName}!`;
    return defaultMessage;
};
