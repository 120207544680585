import { EAuthorization_Permissions, EAuthorization_Features, Nullable } from '@shared/api';
import { BooleanPermissions, BooleanFeatures } from '../interfaces';

export function mapPermissionsToBooleanObject(permissions: Nullable<string>): BooleanPermissions {
    let returned: Partial<BooleanPermissions> = {};
    Object.keys(EAuthorization_Permissions).forEach(
        (key) =>
            (returned[key as EAuthorization_Permissions] = permissions
                ? !!~permissions.indexOf(EAuthorization_Permissions[key as EAuthorization_Permissions])
                : false),
    );
    return returned as BooleanPermissions;
}

export function mapFeaturesToBooleanObject(roles: Nullable<EAuthorization_Features[]>): BooleanFeatures {
    let returned: Partial<BooleanFeatures> = {};
    Object.keys(EAuthorization_Features).forEach(
        (key) =>
            (returned[key as EAuthorization_Features] = roles
                ? !!~roles.indexOf(EAuthorization_Features[key as EAuthorization_Features])
                : false),
    );
    return returned as BooleanFeatures;
}
