export const getApiError = (ex?: any, defaultMessage = 'Something went wrong!') => {
    const messages = ex?.response?.data?.messages || ex?.response?.data?.message;
    if (Array.isArray(messages)) {
        const mainErrorMessage = ex?.response?.data?.mainErrorMessage;
        if (messages && Object.keys(messages).length > 0) {
            const key = Object.keys(messages)[0];
            return messages[key as any];
        } else if (mainErrorMessage) {
            return mainErrorMessage;
        }
    } else {
        return messages || defaultMessage;
    }
};
